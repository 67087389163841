import React from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import PageTitle from '@app/components/pageTitle/PageTitle';
import AddReviewButton from '@app/pages/admin/ReviewsPage/components/AddReviewButton';
import FilterPanel from '@app/pages/admin/ReviewsPage/components/FilterPanel';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import MoreIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import globalAppStore from '@app/stores/globalAppStore';

import { deleteReview, getReviews } from '@app/api/review';
import useDropdownMenu from '@app/hooks/useDropdownMenu';
import usePager from '@app/hooks/usePager';
import useToggle from '@app/hooks/useToggle';
import type { IReview } from '@app/interfaces/review.interface';
import type { ISite } from '@app/interfaces/site.interface';
import type { IClinic } from '@app/interfaces/clinic.interface';
import NavigationBox from '@app/pages/admin/components/NavigationBox';

function ReviewsPage() {
  const [reviews, setReviews] = React.useState<IReview[]>([]);
  const [isOpenEditor, openEditor, closeEditor] = useToggle(false);
  const [selectedReview, setSelectedReview] = React.useState<IReview>();
  const [selectedSite, setSelectedSite] = React.useState<ISite | null>(null);
  const [selectedClinic, setSelectedClinic] = React.useState<IClinic | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [anchorsList, openDropdownMenu, closeDropdownMenu, closeAllDropdownMenu] =
    useDropdownMenu();
  const { limit, page, total, setTotal, changePage, changeRowsPerPage } = usePager();
  const [isDeletingId, setIsDeletingId] = React.useState<number>();

  const fetchReviews = React.useCallback(() => {
    getReviews(limit, page * limit, {}).then(({ reviews, total } = {}) => {
      setReviews(reviews || []);
      setTotal(total || 0);
    });
  }, [limit, page, setTotal]);

  React.useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  React.useEffect(() => {
    if (!isOpenEditor) {
      setSelectedReview(undefined);
    }
  }, [isOpenEditor]);

  const handleEditReview = (review: IReview) => {
    setSelectedReview(review);
    closeAllDropdownMenu();
    openEditor();
  };

  const handleDeleteReview = async (review: IReview) => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление отзыва',
      text: 'Вы уверены, что хотите удалить Отзыв?',
      subtext: '',
      confirmButtonTitle: 'Удалить',
      onConfirm: async () => {
        setIsDeletingId(review.id);
        await deleteReview(review);
        fetchReviews();
        closeAllDropdownMenu();
        setIsDeletingId(undefined);
      },
    });
  };

  const handleSuccess = () => {
    fetchReviews();
  };

  return (
    <div className='content'>
      <PageTitle
        title='Отзывы с платформ'
        addButton={
          <AddReviewButton
            review={selectedReview}
            isOpen={isOpenEditor}
            onOpen={openEditor}
            onClose={closeEditor}
            onSuccess={handleSuccess}
          />
        }
        action={<NavigationBox />}
      />

      <FilterPanel
        setReviews={setReviews}
        site={selectedSite}
        setSite={setSelectedSite}
        clinic={selectedClinic}
        setClinic={setSelectedClinic}
        date={selectedDate}
        setDate={setSelectedDate}
        setTotal={setTotal}
        fetchReviews={fetchReviews}
      />

      <TableContainer component={Paper} sx={{ margin: '24px 0' }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>Платформа</TableCell>
              <TableCell>Клиника</TableCell>
              <TableCell align='right'>Дата отзыва</TableCell>
              <TableCell>Рейтинг</TableCell>
              <TableCell>Клиент</TableCell>
              <TableCell align='center'>
                <TaskAltIcon fontSize='small' />
              </TableCell>
              <TableCell>Отзыв</TableCell>
              <TableCell>Комментарий</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {reviews.map((review, idx) => {
              return (
                <TableRow key={review.id}>
                  <TableCell>{review.site?.name}</TableCell>

                  <TableCell>
                    {review.clinic?.name}
                    {review.doctor ? (
                      <Typography variant='subtitle2' fontWeight={300}>
                        {review.doctor.fio}
                      </Typography>
                    ) : null}
                  </TableCell>

                  <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
                    {formatDate(review.reviewDate)}
                  </TableCell>

                  <TableCell>
                    <Rating name='read-only' value={review.rating} readOnly />
                  </TableCell>

                  <TableCell component='th' scope='row'>
                    <Box>
                      <Typography variant='h6' fontWeight={400}>
                        {getFio(review)}
                      </Typography>
                      <Typography variant='subtitle2' fontWeight={300}>
                        тел.: {review.phone}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell align='center'>
                    <Checkbox
                      inputProps={{ 'aria-label': 'Checkbox demo' }}
                      color='success'
                      checked={review.isRespectClient}
                      disabled
                    />
                  </TableCell>

                  <TableCell>{review.text}</TableCell>

                  <TableCell>{review.comment}</TableCell>

                  <TableCell>
                    <Box>
                      <IconButton
                        size='large'
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={openDropdownMenu(idx)}
                        color='inherit'
                      >
                        <MoreIcon />
                      </IconButton>

                      <Menu
                        id='menu-appbar'
                        anchorEl={anchorsList?.[idx] || null}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        open={Boolean(anchorsList?.[idx])}
                        onClose={closeDropdownMenu(-1)}
                      >
                        <MenuItem onClick={() => handleEditReview(review)}>
                          <ListItemIcon>
                            <EditIcon fontSize='small' color='primary' />
                          </ListItemIcon>
                          <ListItemText>Редактировать</ListItemText>
                        </MenuItem>

                        <Divider />

                        <MenuItem
                          onClick={() => handleDeleteReview(review)}
                          disabled={isDeletingId === review.id}
                        >
                          <ListItemIcon>
                            {isDeletingId === review.id ? (
                              <CircularProgress size={16} color='error' />
                            ) : (
                              <DeleteIcon fontSize='small' color='error' />
                            )}
                          </ListItemIcon>
                          <ListItemText primaryTypographyProps={{ color: 'error' }}>
                            Удалить
                          </ListItemText>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage='Отзывов на странице'
        rowsPerPageOptions={[25, 50]}
        component='div'
        count={total}
        rowsPerPage={limit}
        page={page}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
        sx={{ mb: 3 }}
      />
    </div>
  );
}

const formatDate = (date: Date) => dayjs(date).format('DD MMMM YYYY');

const getFio = (review: IReview) => [review.lastname, review.firstname].filter(Boolean).join(' ');

export default ReviewsPage;
