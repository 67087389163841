import React from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { fetchVisitsInfoByClient, getFullName, getPhone } from '@app/utils/visit';
import useToggle from '@app/hooks/useToggle';
import dayjs from 'dayjs';
import { getClinicFullAddress } from '@app/utils/clinic';
import type { IFeedback } from '@app/interfaces/feedback.interface';
import type { IVisit } from '@app/interfaces/visit.interface';

type Props = {
  visit: IVisit;
};

function ShowVisitsInfoByClient({ visit }: Props) {
  const [isOpen, doOpen, doClose] = useToggle();
  const [info, setInfo] = React.useState<IVisit[] | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      fetchVisitsInfoByClient(visit.client.id)
        .then((data) => setInfo(data))
        .finally(() => setIsLoading(false));
    } else {
      setInfo(null);
    }
  }, [visit.client.id, isOpen]);

  const getApprovedFeedbackCount = (feedbacks: IFeedback[]) => {
    return feedbacks.filter((feedback) => feedback.isFeedbackReceived)?.length;
  };

  return (
    <>
      <IconButton size='large' color='primary' onClick={doOpen}>
        <DirectionsRunIcon />
      </IconButton>

      <Dialog onClose={doClose} open={isOpen} fullWidth maxWidth='lg'>
        <DialogTitle>Информация по визитам</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText primary={getFullName(visit)} secondary={getPhone(visit)} />
            </ListItem>
          </List>

          {isLoading ? (
            <Box sx={{ width: 500 }}>
              <Skeleton animation='wave' />
              <Skeleton animation='wave' />
              <Skeleton animation='wave' />
            </Box>
          ) : (
            <Table sx={{ minWidth: 500 }} size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell>Клиника</TableCell>
                  <TableCell>Дата визита</TableCell>
                  <TableCell align='right'>Отправлено смс</TableCell>
                  <TableCell align='right'>Переход из смс</TableCell>
                  <TableCell align='right'>Отзыв</TableCell>
                  <TableCell align='center'>
                    <TaskAltIcon fontSize='small' />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(info || []).map((visit) => (
                  <TableRow
                    key={visit.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      <Box>
                        <Typography variant='h6'>{visit.clinic.name}</Typography>
                        <Typography variant='body2'>
                          {getClinicFullAddress(visit.clinic)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {dayjs(visit.visitedAt).format('DD MMMM YYYY')}
                    </TableCell>
                    <TableCell align='center'>{visit.sms.length}</TableCell>
                    <TableCell align='center'>{visit.smsLinkClickCount}</TableCell>
                    <TableCell align='center'>{visit.feedbackLinkClickCount}</TableCell>
                    <TableCell align='center'>
                      {getApprovedFeedbackCount(visit.feedbacks)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ShowVisitsInfoByClient;
