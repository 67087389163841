export enum Status {
  need_approval = 'Не подтвержден',
  confirmed_by_administrator = 'Подтвержден администратором (онлайн запись)',
  approved = 'Подвержден',
  arrived = 'Клиент пришел',
  serviced = 'Прием завершен',
  billed = 'Счет выставлен',
  paid = 'Счет оплачен',
  canceled = 'Отменен',
  failed = 'Неявка',
}
