import PageTitle from '@app/components/pageTitle/PageTitle';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { fetchIdentReceptions } from '@app/utils/ident';
import type { IIdentReception } from '@app/interfaces/ident.reception.interface';

dayjs.extend(utc);

const IdentReceptionsPage = () => {
  const [date, setDate] = React.useState<Dayjs>(() => dayjs());
  const [receptions, setReceptions] = React.useState<IIdentReception[]>([]);

  React.useEffect(() => {
    fetchIdentReceptions(date).then((data) => setReceptions(data));
  }, [date]);

  return (
    <div className='content'>
      <PageTitle title='IDENT визиты' />

      <Box
        sx={{
          flexGrow: 1,
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '230px 1fr 1fr',
          justifyContent: 'start',
          gap: '24px',
        }}
      >
        <DatePicker value={date} onChange={(val) => setDate(val as Dayjs)} format='DD MMMM YYYY' />
      </Box>

      <TableContainer component={Paper} sx={{ margin: '24px 0' }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell component='th'>Пациент</TableCell>
              <TableCell component='th' align='right'>
                Визит
              </TableCell>
              <TableCell component='th'>Комментарий</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(receptions || []).map((reception) => (
              <TableRow
                key={reception.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Typography variant='h6' fontWeight={400} noWrap>
                    {getFullName(reception)}
                  </Typography>
                  <Typography variant='subtitle2' fontWeight={300}>
                    тел.: {reception.userPhone}
                  </Typography>
                </TableCell>

                <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
                  <Typography variant='subtitle2' fontWeight={400} noWrap>
                    {formatDate(reception.userReceptionAppeared)}
                  </Typography>
                  <Typography variant='subtitle2' fontWeight={300} noWrap>
                    {formatTime(reception.receptionStarted)} -{' '}
                    {formatTime(reception.receptionEnded)}
                  </Typography>
                </TableCell>

                <TableCell>
                  {reception.comment ? (
                    <Typography variant='subtitle2' fontWeight={400}>
                      {reception.comment}
                    </Typography>
                  ) : null}
                  {reception.additionalComment ? (
                    <Typography variant='subtitle2' fontWeight={300}>
                      {reception.additionalComment}
                    </Typography>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const getFullName = ({ userName, userPatronymic, userSurname }: IIdentReception) =>
  [userSurname, userName, userPatronymic].filter(Boolean).join(' ');

const formatDate = (date: Date) => dayjs.utc(date).format('DD MMMM YYYY');
const formatTime = (date: Date) => dayjs.utc(date).format('HH:mm');

export default IdentReceptionsPage;
