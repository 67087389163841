import React from 'react';
import { action, makeObservable, observable } from 'mobx';

class FormStore<T> {
  item: T;

  isEditorOpen = false;

  isLoading = false;

  total = 0;

  limit = 25;

  page = 0;

  constructor(initial: T) {
    this.item = initial;

    makeObservable(this, {
      item: observable,
      isEditorOpen: observable,
      isLoading: observable,
      total: observable,
      limit: observable,
      page: observable,
      setItem: action,
      setItemField: action,
      setIsEditorOpen: action,
      setIsLoading: action,
      setTotal: action,
      setLimit: action,
      setPage: action,
      setRowsPerPage: action,
    });

    this.openEditor = this.openEditor.bind(this);
    this.closeEditor = this.closeEditor.bind(this);
  }

  setItem(item: T) {
    this.item = item;
  }

  setItemField(field: keyof T, value: any) {
    if (this.item) {
      this.item[field] = value;
    }
  }

  setIsEditorOpen(isOpen: boolean) {
    this.isEditorOpen = isOpen;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setTotal(total: number) {
    this.total = total;
  }

  setLimit(limit: number) {
    this.limit = limit;
  }

  setPage(page: number) {
    this.page = page;
  }

  setRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    this.setLimit(parseInt(event.target.value, 10));
    this.setPage(0);
  }

  openEditor() {
    this.setIsEditorOpen(true);
  }

  closeEditor() {
    this.setIsEditorOpen(false);
  }
}

export default FormStore;
