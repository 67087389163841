import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import SettingBlockOperatorTab from '@app/pages/clinicPage/components/settingBlockOperatorTab/SettingBlockOperatorTab';
import SettingBlockSiteTab from '@app/pages/clinicPage/components/settingBlockSiteTab/SettingBlockSiteTab';
import SettingBlockSmsTab from '@app/pages/clinicPage/components/settingBlockSmsTab/SettingBlockSmsTab';
import SettingBlockMessageTab from '@app/pages/clinicPage/components/settingBlockMessageTab/SettingBlockMessageTab';

import type { IClinic } from '@app/interfaces/clinic.interface';
import SettingBlockIntegrationTab from '@app/pages/clinicPage/components/settingBlockIntegrationTab/settingBlockIntegrationTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface SettingBlockProps {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`setting-block-tabpanel-${index}`}
      aria-labelledby={`setting-block-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `setting-block-tab-${index}`,
    'aria-controls': `setting-block-tabpanel-${index}`,
  };
}

function SettingBlock({ clinic, setClinic }: SettingBlockProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='setting-block'>
          <Tab label='Площадки' {...a11yProps(0)} />
          <Tab label='Операторы' {...a11yProps(1)} />
          <Tab label='СМС' {...a11yProps(2)} />
          <Tab label='Сообщения' {...a11yProps(3)} />
          <Tab label='Интеграция' {...a11yProps(4)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <SettingBlockSiteTab clinic={clinic} setClinic={setClinic} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <SettingBlockOperatorTab clinic={clinic} setClinic={setClinic} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <SettingBlockSmsTab clinic={clinic} setClinic={setClinic} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <SettingBlockMessageTab clinic={clinic} setClinic={setClinic} />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <SettingBlockIntegrationTab clinic={clinic} setClinic={setClinic} />
      </CustomTabPanel>
    </Box>
  );
}

export default SettingBlock;
