import { makeAutoObservable } from 'mobx';
import { getLocalStorageItem, setLocalStorageItem } from '@app/helpers/localStorageHelper';
import { APP_LOCALSTORAGE_CLINIC_ID_KEY } from '@app/constants/common';
import { fetchClinics } from '@app/utils/clinic';
import type { IClinic } from '@app/interfaces/clinic.interface';

class ClinicsStore {
  clinics: IClinic[] = [];

  clinicId?: number = getLocalStorageItem(APP_LOCALSTORAGE_CLINIC_ID_KEY);

  constructor() {
    makeAutoObservable(this);

    this.fetchClinics();
  }

  setClinics(clinics: IClinic[]) {
    this.clinics = clinics;
  }

  setClinic(clinic: IClinic) {
    this.clinicId = clinic.id;
    setLocalStorageItem(APP_LOCALSTORAGE_CLINIC_ID_KEY, clinic.id);
  }

  get clinic() {
    return this.clinics?.find((clinic) => clinic.id === this.clinicId);
  }

  get medodsClinics() {
    return this.clinics?.filter((clinic) => clinic.integration === 'MEDODS');
  }

  *fetchClinics() {
    const clinics: IClinic[] = yield fetchClinics();
    if (clinics) {
      this.setClinics(clinics);
    }
  }
}

// eslint-disable-next-line
export default new ClinicsStore();
