import React from 'react';

function usePager() {
  const [limit, setLimit] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  const changePage = (event: unknown, page: number) => {
    setPage(page);
  };

  const changeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return { limit, page, total, setTotal, changePage, changeRowsPerPage };
}

export default usePager;
