import React from 'react';

function useToggle(
  initial = false
): [isOpen: boolean, doOpen: () => void, doClose: () => void] {
  const [isOpen, setIsOpen] = React.useState(initial);

  const doOpen = () => setIsOpen(true);

  const doClose = () => setIsOpen(false);

  return [isOpen, doOpen, doClose];
}

export default useToggle;
