import { doDelete, doGet, doPatch, doPost, doPut } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IOperator } from '@app/interfaces/operator.interface';
import type { IClient } from '@app/interfaces/client.interface';

export const toggleProdoctorovDoctorFeedback = async (clinicId: number) => {
  try {
    const result = await doPatch(`/api/clinics/${clinicId}/prodoctorov-feedback`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteClinic = async (clinicId: number) => {
  try {
    const result = await doDelete(`/api/clinics/${clinicId}`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const toggleOperatorSendSmsPermit = async (clinicId: number) => {
  try {
    const result = await doPatch(`/api/clinics/${clinicId}/operator-send-sms-permit`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const saveClientSmsTemplate = async (clinicId: number, template: string) => {
  try {
    const result = await doPatch(`/api/clinics/${clinicId}/client-sms`, { template });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const saveTemplate = async (clinicId: number, name: string, template: string) => {
  try {
    const result = await doPatch(`/api/clinics/${clinicId}/template/${name}`, { template });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const downloadClinicFeedbackReport = async (clinic: IClinic) => {
  try {
    const result = await doGet(`/api/clinics/${clinic.id}/feedback-report`, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${clinic.name}-отзывы.xlsx`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (e) {
    console.log('e', e);
  }
};

export const saveVisits = async (
  clinicId: number,
  operator: IOperator,
  clients: Omit<IClient, 'id'>[]
) => {
  await doPost(`/api/clinics/${clinicId}/visits`, {
    visits: clients,
    operator,
  });
};

export const updateClinic = async (clinic: IClinic) => {
  return await doPut(`/api/clinics/${clinic.id}`, { clinic });
};
