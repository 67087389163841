import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import type { IClinic } from '@app/interfaces/clinic.interface';
import { EMPTY_CLINIC, NewClinicField, NewClinicFormConfig } from '@app/constants/clinic';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { doPost } from '@app/api';
import useToggle from '@app/hooks/useToggle';
import useClinic from '@app/hooks/useClinic';

type NewClinic = Pick<
  IClinic,
  | NewClinicField.NAME
  | NewClinicField.CITY
  | NewClinicField.ADDRESS
  | NewClinicField.SLUG
  | NewClinicField.INFO
  | NewClinicField.EMAIL
  | NewClinicField.SMS_PRICE
>;

function AddClinicButton({ onSuccess }: { onSuccess: (clinic: IClinic) => void }) {
  const [isOpen, doOpen, doClose] = useToggle(false);
  const [clinic, setClinic] = React.useState<NewClinic>(EMPTY_CLINIC);
  const [changeClinic] = useClinic<NewClinic>(clinic, setClinic);

  const addClinic = async () => {
    const result = await doPost('/api/clinics', { clinic });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      onSuccess(result.data);
      setClinic(EMPTY_CLINIC);
      doClose();
    }
  };

  const isValidForm = () => {
    return NewClinicFormConfig.filter(({ required }) => required).every(({ field }) =>
      Boolean(clinic[field])
    );
  };

  return (
    <>
      <Button variant='outlined' onClick={doOpen}>
        Добавить Клинику
      </Button>
      <Dialog open={isOpen} onClose={doClose}>
        <DialogTitle>Клиника</DialogTitle>

        <DialogContent>
          {NewClinicFormConfig.map(({ label, field, required, type }, idx) => {
            return (
              <TextField
                key={field}
                autoFocus={idx === 0}
                margin='dense'
                id={field}
                label={label}
                fullWidth
                required={required}
                type={type ?? 'text'}
                value={clinic[field]}
                onChange={changeClinic(field)}
              />
            );
          })}
        </DialogContent>

        <DialogActions>
          <Button onClick={addClinic} disabled={!isValidForm()}>
            Добавить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddClinicButton;
