import React from 'react';

function useClinic<Clinic>(
  clinic: Clinic,
  setClinic: (clinic: Clinic) => void
): [(field: string) => (e: React.ChangeEvent<HTMLInputElement>) => void] {
  const change = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setClinic({
      ...clinic,
      [field]: e.target.value,
    });
  };

  return [change];
}

export default useClinic;
