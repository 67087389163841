import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import type { IClinic } from '@app/interfaces/clinic.interface';
import { getRequiredField, NewClinicFormConfig } from '@app/constants/clinic';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { doPut } from '@app/api';
import useToggle from '@app/hooks/useToggle';

function EditClinicButton({
  clinic,
  setClinic,
}: {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}) {
  const [isOpen, doOpen, doClose] = useToggle();

  const changeClinic =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setClinic({
        ...clinic,
        [field]: e.target.value,
      });
    };

  const updateClinic = async () => {
    const result = await doPut(`/api/clinics/${clinic.id}`, { clinic });

    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      doClose();
    }
  };

  const isValidForm = () => {
    const requiredField = getRequiredField();
    return requiredField.every((field) => !!clinic?.[field]);
  };

  return (
    <>
      <IconButton aria-label='edit clinic' onClick={doOpen} color='success'>
        <EditIcon />
      </IconButton>
      <Dialog open={isOpen} onClose={doClose}>
        <DialogTitle>Клиника</DialogTitle>

        <DialogContent>
          {NewClinicFormConfig.map(({ label, field, required }, idx) => {
            return (
              <TextField
                key={field}
                autoFocus={idx === 0}
                margin='dense'
                id={field}
                label={label}
                fullWidth
                value={clinic[field]}
                onChange={changeClinic(field)}
                required={required}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={updateClinic} disabled={!isValidForm()}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditClinicButton;
