import React from 'react';
import { Route } from 'react-router-dom';
import { AppRoute } from '@app/constants/common';
import PrivateRoute from '@app/routes/private-route';
import AdminLayout from '@app/layouts/admin/AdminLayout';
import AdminLayoutFullWidth from '@app/layouts/admin/AdminLayoutFullWidth';
import AdminLoginLayout from '@app/layouts/adminLogin/AdminLoginLayout';
import AdminFeedbacksPage from '@app/pages/adminFeedbacksPage/AdminFeedbacksPage';
import ClinicsPage from '@app/pages/clinicsPage/ClinicsPage';
import VisitsPage from '@app/pages/visitsPage/VisitsPage';
import ClinicPage from '@app/pages/clinicPage/ClinicPage';
import LoginPage from '@app/pages/loginPage/LoginPage';
import HomePage from '@app/pages/homePage/HomePage';
import IdentReceptionsPage from '@app/pages/admin/IdentReceptionsPage/IdentReceptionsPage';
import AnalyticsPage from '@app/pages/admin/AnalyticsPage/AnalyticsPage';
import SitesPage from '@app/pages/admin/SitesPage/SitesPage';
import ReviewsPage from '@app/pages/admin/ReviewsPage/ReviewsPage';
import RatingPage from '@app/pages/admin/RatingPage/RatingPage';
import TestPage from '@app/pages/admin/TestPage/TestPage';
import AdminDesignedLayout from '@app/layouts/admin/AdminDesignedLayout/AdminDesignedLayout';
// INTEGRATIONS
import MedodsPage from '@app/pages/admin/Integrations/Medods/MedodsPage';

const ADMIN_DESIGNED_LAYOUT_ROUTES = [
  {
    path: AppRoute.ANALYTICS,
    component: AnalyticsPage,
    exact: true,
  },
  {
    path: AppRoute.SITES,
    component: SitesPage,
    exact: true,
  },
  {
    path: AppRoute.REVIEWS,
    component: ReviewsPage,
    exact: true,
  },
  {
    path: AppRoute.RATING,
    component: RatingPage,
    exact: true,
  },
  // INTEGRATIONS
  {
    path: AppRoute.MEDODS,
    component: MedodsPage,
    exact: true,
  },
];

const ADMIN_ROUTES = [
  {
    path: AppRoute.IDENT_RECEPTIONS,
    component: IdentReceptionsPage,
    exact: true,
  },
  { path: AppRoute.CLINICS, component: ClinicsPage, exact: true },
  { path: AppRoute.CLINIC_ID, component: ClinicPage },
  {
    path: AppRoute.ADMIN_FEEDBACKS,
    component: AdminFeedbacksPage,
    exact: true,
  },
];

const ADMIN_ROUTES_FULLWIDTH_LAYOUT = [
  { path: AppRoute.VISITS, component: VisitsPage, exact: true },
];

const ADMIN_TEST_ROUTES = [
  {
    path: AppRoute.TEST,
    component: TestPage,
    exact: true,
  },
];

export function AdminDesignedLayoutRoutes() {
  return ADMIN_DESIGNED_LAYOUT_ROUTES.map(({ path, component: Component }) => (
    <PrivateRoute
      key={path}
      path={path}
      exact
      render={() => (
        <AdminDesignedLayout>
          <Component />
        </AdminDesignedLayout>
      )}
    />
  ));
}

export function AdminRoutes() {
  return ADMIN_ROUTES.map(({ path, component: Component }) => (
    <PrivateRoute
      key={path}
      path={path}
      exact
      render={() => (
        <AdminLayout>
          <Component />
        </AdminLayout>
      )}
    />
  ));
}

export function ExtraAdminRoutes() {
  return ADMIN_ROUTES_FULLWIDTH_LAYOUT.map(({ path, component: Component }) => (
    <PrivateRoute
      key={path}
      path={path}
      exact
      render={() => (
        <AdminLayoutFullWidth>
          <Component />
        </AdminLayoutFullWidth>
      )}
    />
  ));
}

export function AdminLoginRoute() {
  return (
    <Route path={AppRoute.LOGIN} exact>
      <AdminLoginLayout>
        <LoginPage />
      </AdminLoginLayout>
    </Route>
  );
}

export function HomeRoute() {
  return (
    <Route path={AppRoute.HOME} exact>
      <HomePage />
    </Route>
  );
}

export function TestRoutes() {
  return ADMIN_TEST_ROUTES.map(({ path, component: Component }) => (
    <PrivateRoute
      key={path}
      path={path}
      exact
      render={() => (
        <AdminLayout>
          <Component />
        </AdminLayout>
      )}
    />
  ));
}
