import React from 'react';
import PageTitle from '@app/components/pageTitle/PageTitle';
import AddClinicButton from '@app/pages/clinicsPage/components/addClinicButton/AddClinicButton';
import ClinicsList from '@app/pages/clinicsPage/components/ClinicsList';

import type { IClinic } from '@app/interfaces/clinic.interface';
import { fetchClinics } from '@app/utils/clinic';

function ClinicsPage() {
  const [clinics, setClinics] = React.useState<IClinic[]>([]);

  React.useEffect(() => {
    fetchClinics().then((clinics) => setClinics(clinics));
  }, []);

  const addClinic = (clinic: IClinic) => setClinics([clinic, ...clinics]);

  return (
    <div className='content'>
      <PageTitle
        title='Клиники'
        action={<AddClinicButton onSuccess={addClinic} />}
      />

      <ClinicsList clinics={clinics} />
    </div>
  );
}

export default ClinicsPage;
