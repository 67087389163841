import React from 'react';
import ClinicOuterSiteIdsList from '@app/pages/clinicPage/components/clinicOuterSiteIdsList';
import type { IClinic } from '@app/interfaces/clinic.interface';

interface Props {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}

function SettingBlockSiteTab({ clinic, setClinic }: Props) {
  return <ClinicOuterSiteIdsList clinic={clinic} setClinic={setClinic} />;
}

export default SettingBlockSiteTab;
