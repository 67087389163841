import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import type { ISite } from '@app/interfaces/site.interface';
import React from 'react';

type Props = {
  site: ISite | null;
  sites: ISite[];
  setSite: (site: ISite) => void;
  size?: string;
};

function SiteSelect({ site, sites, setSite, size }: Props) {
  const handleSelect = (e: SelectChangeEvent<string>) => {
    const _site = sites.find((s) => s.id === +e.target.value);

    if (_site) {
      setSite(_site);
    }
  };

  if (!sites?.length) {
    return null;
  }

  return (
    <Box sx={{ marginTop: 1, display: 'flex', alignItems: 'center' }}>
      <FormControl sx={{}} fullWidth size={size === 'small' ? 'small' : undefined}>
        <InputLabel id='site-select'>Платформа</InputLabel>
        <Select
          labelId='site-select'
          id='site'
          label='Платформа'
          value={site?.id?.toString() || ''}
          onChange={handleSelect}
        >
          {sites.map((site) => {
            return (
              <MenuItem key={site.id} value={site.id} divider dense>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{site.name}</Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SiteSelect;
