import React from 'react';
import styled from '@emotion/styled';
import {
  Badge,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { saveClientSmsTemplate, toggleOperatorSendSmsPermit } from '@app/api/clinic';
import type { IClinic } from '@app/interfaces/clinic.interface';

interface Props {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}

const DEFAULT_CLIENT_SMS =
  '%FIRST_NAME%, расскажите, понравилось ли Вам в %CLINIC_NAME%? _LINK_ В благодарность отправим Вам на телефон %SMS_PRICE% руб.';

function SettingBlockSmsTab({ clinic, setClinic }: Props) {
  const [tmplClientSMS, setTmplClientSMS] = React.useState(
    clinic.clientSmsTemplate || DEFAULT_CLIENT_SMS
  );

  const clientTmplConfig = React.useMemo(
    () => [
      {
        tmpl: '%FIRST_NAME%',
        label: 'Имя пациента',
        value: 'Иван',
      },
      {
        tmpl: '%CLINIC_NAME%',
        label: 'Название клиники',
        value: clinic.name,
      },
      {
        tmpl: '%SMS_PRICE%',
        label: 'Стоимость sms',
        value: clinic.smsPrice,
      },
    ],
    [clinic.name, clinic.smsPrice]
  );

  const clientSms = React.useMemo(() => {
    let tmplString = tmplClientSMS;

    clientTmplConfig.forEach(({ tmpl, value }) => {
      tmplString = tmplString.replace(tmpl, value);
    });

    return tmplString;
  }, [clientTmplConfig, tmplClientSMS]);

  const smsLength = React.useMemo(() => {
    let tmplString = tmplClientSMS;

    tmplString = tmplString.replace('%FIRST_NAME%', '');
    tmplString = tmplString.replace('%CLINIC_NAME%', clinic.name);
    tmplString = tmplString.replace('_LINK_', '');
    tmplString = tmplString.replace('%SMS_PRICE%', clinic.smsPrice);

    return tmplString.length;
  }, [tmplClientSMS, clinic]);

  const handleTmplClientSmsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmplClientSMS(e.target.value);
  };

  const handleSaveClientSmsTemplate = async () => {
    saveClientSmsTemplate(clinic.id, tmplClientSMS).then((clinic) => {
      if (clinic) {
        setClinic(clinic);
      }
    });
  };

  const handleToggleOperatorSendSmsPermit = async () => {
    toggleOperatorSendSmsPermit(clinic?.id!).then((clinic) => {
      if (clinic) {
        setClinic(clinic);
      }
    });
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={clinic.isOperatorPermittedToSendSms}
              onChange={handleToggleOperatorSendSmsPermit}
            />
          }
          label='Отправка СМС Оператором клиники'
        />
      </FormGroup>

      <Divider />

      <Paper variant='outlined' sx={{ padding: 2 }}>
        {clientTmplConfig.map(({ tmpl, label }) => (
          <Typography key={label}>{`${tmpl} - ${label}`}</Typography>
        ))}
        <Typography color={'error'}>_LINK_ - не менять, не удалять!!!</Typography>
      </Paper>

      <Divider />

      <GridWrapper>
        <ClientTextField
          id='client-sms-template'
          label='Шаблон'
          multiline
          rows={3}
          fullWidth
          value={tmplClientSMS}
          onChange={handleTmplClientSmsChange}
        />

        <Badge badgeContent={`${smsLength}*`} color='primary'>
          <ClientTextField
            id='welcome-sms-example'
            label='Итог'
            multiline
            rows={3}
            fullWidth
            disabled
            value={clientSms}
          />
        </Badge>
      </GridWrapper>

      <Typography variant={'body2'} color='grey' sx={{ paddingTop: '8px' }}>
        * Длина СМС расчина без учета Имени Пациента и сгенерированной ссылки
      </Typography>

      <Divider />

      <Button
        onClick={handleSaveClientSmsTemplate}
        disabled={clinic?.clientSmsTemplate === tmplClientSMS}
      >
        Сохранить
      </Button>
    </>
  );
}

const ClientTextField = styled(TextField)<TextFieldProps>({
  '.MuiInputBase-root': {
    background: '#fafff8',
  },
});

const Divider = styled.div`
  height: 30px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export default SettingBlockSmsTab;
