import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Switch,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import type { IClinic } from '@app/interfaces/clinic.interface';
import useToggle from '@app/hooks/useToggle';
import {
  addDoctor,
  deleteDoctor,
  fetchDoctorsByClinic,
  updateDoctor,
} from '@app/api/doctor';
import { IDoctor } from '@app/interfaces/doctor.interface';
import DoctorCard from '@app/pages/clinicPage/components/doctorCard/DoctorCard';
import DoctorEditForm from '@app/pages/clinicPage/components/doctorEditForm/DoctorEditForm';
import { toggleProdoctorovDoctorFeedback } from '@app/api/clinic';

type Props = {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
};

function EditDoctorListButton({ clinic, setClinic }: Props) {
  const [doctors, setDoctors] = React.useState<IDoctor[]>([]);
  const [isOpen, doOpen, doClose] = useToggle(false);
  const [isAddDoctor, setIsAddDoctor] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      setIsAddDoctor(false);
      fetchDoctorsByClinic(clinic.id).then((data: IDoctor[]) => {
        setDoctors(data);

        if (!data.length) {
          setIsAddDoctor(true);
        }
      });
    }
  }, [isOpen, clinic.id]);

  const saveDoctor = async (doctor: Partial<IDoctor>) => {
    addDoctor(clinic.id, doctor).then((data) => setDoctors([...doctors, data]));
  };

  const handleUpdateDoctor = async (
    doctor: Partial<IDoctor>,
    index: number
  ) => {
    updateDoctor(doctor).then((data) => {
      setDoctors([
        ...doctors.slice(0, index),
        data,
        ...doctors.slice(index + 1),
      ]);
    });
  };

  const handleDeleteDoctor = async (doctor: IDoctor) => {
    deleteDoctor(doctor).then((data: IDoctor) => {
      if (data.isDeleted) {
        setDoctors(doctors.filter((d) => d.id !== doctor.id));
      }
    });
  };

  const handleDoctorFeedbackToggle = async () => {
    toggleProdoctorovDoctorFeedback(clinic.id).then((data) => setClinic(data));
  };

  return (
    <>
      <IconButton onClick={doOpen}>
        {clinic.isProdoctorovDoctorFeedback ? (
          <PersonIcon color={'success'} />
        ) : (
          <PersonOffIcon color='disabled' />
        )}
      </IconButton>

      <Dialog open={isOpen} onClose={doClose} maxWidth='sm' fullWidth={true}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '24px',
          }}
        >
          <DialogTitle>ПроДокторов | Доктора</DialogTitle>
          <Switch
            edge='end'
            onChange={handleDoctorFeedbackToggle}
            checked={clinic.isProdoctorovDoctorFeedback}
          />
        </Box>
        <DialogContent>
          {doctors.map((doctor, index) => (
            <>
              <DoctorCard
                key={doctor.id}
                doctor={doctor}
                onEdit={(doctor) => handleUpdateDoctor(doctor, index)}
                onDelete={handleDeleteDoctor}
              />
              <Divider
                key={doctor.id + index.toString()}
                sx={{ height: 20, borderBottom: 'none' }}
              />
            </>
          ))}

          {isAddDoctor && (
            <DoctorEditForm
              onSave={saveDoctor}
              onCancel={() => setIsAddDoctor(false)}
            />
          )}
        </DialogContent>

        {!isAddDoctor && (
          <DialogActions>
            <Button onClick={() => setIsAddDoctor(true)}>Добавить</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

export default EditDoctorListButton;
