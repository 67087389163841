import React from 'react';

function useForm<T>(initial: T) {
  const [item, setItem] = React.useState<T>(initial);

  const changeInput = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setItem({
      ...item,
      [field]: e.target.value,
    });
  };

  const changeItem =
    <U>(field: string) =>
    (e: U) => {
      setItem({
        ...item,
        [field]: e,
      });
    };

  const reset = React.useCallback(() => {
    setItem(initial);
  }, [initial]);

  return { item, setItem, changeInput, changeItem, reset };
}

export default useForm;
