import React from 'react';
import { Box, Container } from '@mui/material';
import Header from '@app/layouts/admin/components/header/Header';

interface MainPageLayoutProps {
  children: React.ReactElement;
  className?: string;
}

function AdminLayout({ children }: MainPageLayoutProps) {
  return (
    <>
      <Header />
      <Container maxWidth='lg'>
        <Box sx={{ marginTop: '24px' }}>{children}</Box>
      </Container>
    </>
  );
}

export default AdminLayout;
