import React from 'react';
import styled from '@emotion/styled';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import EditClinicButton from '@app/pages/clinicPage/components/editClinicButton/EditClinicButton';
import SettingBlock from '@app/pages/clinicPage/components/settingBlock/SettingBlock';
import EmailIcon from '@mui/icons-material/Email';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';

import globalAppStore from '@app/stores/globalAppStore';

import type { IClinic } from '@app/interfaces/clinic.interface';
import { getClinic, getClinicAcronym, getClinicFullAddress } from '@app/utils/clinic';
import { deleteClinic } from '@app/api/clinic';
import { red } from '@mui/material/colors';
import { AppRoute } from '@app/constants/common';
import SmsCountInfo from '@app/pages/clinicPage/components/smsCountInfo/SmsCountInfo';
import IdentToggler from '@app/pages/clinicPage/components/identToggler/IdentToggler';

function ClinicPage() {
  const history = useHistory();
  const { clinicId }: { clinicId?: string } = useParams();
  const [clinic, setClinic] = React.useState<IClinic | null>(null);

  React.useEffect(() => {
    if (clinicId) {
      getClinic(+clinicId).then((clinic) => {
        if (clinic) {
          setClinic(clinic);
        }
      });
    }
  }, [clinicId]);

  const handleDelete = async () => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление Клиники',
      text: clinic?.name || '',
      subtext: clinic?.address || '',
      confirmButtonTitle: 'Удалить',
      onConfirm: () =>
        deleteClinic(clinic?.id!).then(() => {
          history.push(AppRoute.CLINICS);
        }),
    });
  };

  if (!clinic) {
    return null;
  }

  return (
    <>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500], width: 56, height: 56 }} aria-label='clinic'>
              {getClinicAcronym(clinic)}
            </Avatar>
          }
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CardActions disableSpacing>
                <Link to={AppRoute.CLINICS}>
                  <Button startIcon={<ArrowBackIosIcon />}>К списку</Button>
                </Link>
              </CardActions>

              <Divider orientation='vertical' variant='middle' flexItem />

              <CardActions disableSpacing>
                <EditClinicButton clinic={clinic} setClinic={setClinic} />
              </CardActions>

              <Divider orientation='vertical' variant='middle' flexItem />

              <CardActions disableSpacing>
                <IconButton aria-label='delete-doctor' onClick={handleDelete}>
                  <DeleteIcon sx={{ height: 24, width: 24 }} color='error' />
                </IconButton>
              </CardActions>
            </Box>
          }
          title={clinic.name}
          subheader={getClinicFullAddress(clinic)}
          titleTypographyProps={{ variant: 'h5' }}
        />

        <CardContent>
          <Typography variant='body2' color='text.secondary'>
            {clinic.info}
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              justifyContent: 'start',
              gap: 10,
              mt: 2,
            }}
          >
            <Box>
              <Typography variant='h6'>Почта:</Typography>

              <List dense={true}>
                <ListItem disablePadding>
                  <ListItemAvatar sx={{ display: 'flex' }}>
                    <EmailIcon color='primary' />
                  </ListItemAvatar>
                  <ListItemText
                    primary={clinic.email || '-'}
                    primaryTypographyProps={{ color: 'text.secondary' }}
                  />
                </ListItem>
              </List>
            </Box>

            <Box>
              <Typography variant='h6'>Стоимость SMS:</Typography>

              <List dense={true}>
                <ListItem disablePadding>
                  <ListItemAvatar sx={{ display: 'flex' }}>
                    <CurrencyRubleIcon color='primary' />
                  </ListItemAvatar>
                  <ListItemText
                    primary={clinic.smsPrice || '-'}
                    primaryTypographyProps={{ color: 'text.primary' }}
                  />
                </ListItem>
              </List>
            </Box>

            <SmsCountInfo clinic={clinic} setClinic={setClinic} />

            <IdentToggler clinic={clinic} setClinic={setClinic} />
          </Box>
        </CardContent>
      </Card>

      <Divider20 />

      <SettingBlock clinic={clinic} setClinic={setClinic} />
    </>
  );
}

const Divider20 = styled.div`
  height: 20px;
`;

export default ClinicPage;
