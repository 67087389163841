import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import globalAppStore from '@app/stores/globalAppStore';

import { AppRoute, USER_ROLE } from '@app/constants/common';
import { observer } from 'mobx-react-lite';

interface PrivateRouteProps {
  exact: boolean;
  path: string;
  render: () => React.ReactNode;
}

const getAdequatePathRoles = (path: string) =>
  ({
    [AppRoute.IDENT_RECEPTIONS]: [USER_ROLE.ADMIN],
    [AppRoute.ADMIN_FEEDBACKS]: [USER_ROLE.ADMIN],
    [AppRoute.CLINICS]: [USER_ROLE.ADMIN],
    [AppRoute.CLINIC_ID]: [USER_ROLE.ADMIN],
    [AppRoute.VISITS]: [USER_ROLE.ADMIN, USER_ROLE.OPERATOR],
    [AppRoute.ANALYTICS]: [USER_ROLE.ADMIN],
    [AppRoute.SITES]: [USER_ROLE.ADMIN],
    [AppRoute.REVIEWS]: [USER_ROLE.ADMIN],
    [AppRoute.RATING]: [USER_ROLE.ADMIN],
    [AppRoute.TEST]: [USER_ROLE.ADMIN],
    [AppRoute.MEDODS]: [USER_ROLE.ADMIN],
  })[path] || [];

const PrivateRoute = observer(({ render, path, exact }: PrivateRouteProps) => {
  const role = globalAppStore.userRole;
  const rootPage = AppRoute.VISITS;
  const isAdequateRolePath = role && getAdequatePathRoles(path).includes(role);

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return isAdequateRolePath ? render() : <Redirect to={rootPage} />;
      }}
    />
  );
});

export default PrivateRoute;
