import React from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import NavigationBox from '@app/pages/admin/components/NavigationBox';
import PageTitle from '@app/components/pageTitle/PageTitle';
import AddRatingButton from '@app/pages/admin/RatingPage/components/AddRatingButton';
import SiteSelect from '@app/pages/admin/ReviewsPage/components/SiteSelect';
import MoreIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';

import globalAppStore from '@app/stores/globalAppStore';
import RatingPageStore from '@app/pages/admin/RatingPage/stores/ratingPageStore';

import useDropdownMenu from '@app/hooks/useDropdownMenu';
import type { IRating } from '@app/interfaces/rating.interface';

const RatingPage = observer(() => {
  const [store] = React.useState(() => new RatingPageStore());
  const [anchorsList, openDropdownMenu, closeDropdownMenu, closeAllDropdownMenu] =
    useDropdownMenu();
  const [isDeletingId, setIsDeletingId] = React.useState<number>();

  const handleDelete = async (rating: IRating) => {
    closeAllDropdownMenu();

    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление рейтинга',
      text: 'Вы уверены, что хотите удалить рейтинг?',
      subtext: '',
      confirmButtonTitle: 'Удалить',
      onConfirm: async () => {
        setIsDeletingId(rating.id);
        await store.deleteRating(rating);
        setIsDeletingId(undefined);
      },
    });
  };

  return (
    <div className='content'>
      <PageTitle
        title='Рейтинг'
        addButton={<AddRatingButton store={store} />}
        action={<NavigationBox />}
      />

      <Grid container columnSpacing={2} alignItems='center'>
        <Grid item xs={3}>
          <SiteSelect
            site={store.item.site}
            sites={store.sites}
            setSite={(val) => store.setItemField('site', val)}
            size='small'
          />
        </Grid>

        <Grid item sx={{ mt: '8px' }}>
          <Button
            variant='outlined'
            size='medium'
            color='error'
            startIcon={<ClearIcon />}
            disabled={store.isLoading || !store.item.site}
            onClick={() => {}}
          >
            Сброс
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ margin: '24px 0' }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>Платформа</TableCell>
              <TableCell>Клиника</TableCell>
              <TableCell align='right'>Дата рейтинга</TableCell>
              <TableCell align='center'>Рейтинг</TableCell>
              <TableCell>Комментарий</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {store.ratings.map((rating, idx) => (
              <TableRow key={rating.id}>
                <TableCell>{rating.site?.name}</TableCell>

                <TableCell>{rating.clinic?.name}</TableCell>

                <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
                  {formatDate(rating.ratingDate)}
                </TableCell>

                <TableCell align='center'>
                  <Typography variant='h6' fontWeight={400}>
                    {rating.rating}
                  </Typography>
                </TableCell>

                <TableCell>{rating.comment}</TableCell>

                <TableCell>
                  <Box>
                    <IconButton
                      size='large'
                      aria-label='account of current user'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      onClick={openDropdownMenu(idx)}
                      color='inherit'
                    >
                      <MoreIcon />
                    </IconButton>

                    <Menu
                      id='menu-appbar'
                      anchorEl={anchorsList?.[idx] || null}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorsList?.[idx])}
                      onClose={closeDropdownMenu(-1)}
                    >
                      <MenuItem
                        onClick={() => {
                          store.setItem(rating);
                          store.openEditor();
                          closeAllDropdownMenu();
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon fontSize='small' color='primary' />
                        </ListItemIcon>
                        <ListItemText>Редактировать</ListItemText>
                      </MenuItem>

                      <Divider />

                      <MenuItem
                        onClick={() => {
                          handleDelete(rating);
                        }}
                        disabled={isDeletingId === rating.id}
                      >
                        <ListItemIcon>
                          {isDeletingId === rating.id ? (
                            <CircularProgress size={16} color='error' />
                          ) : (
                            <DeleteIcon fontSize='small' color='error' />
                          )}
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ color: 'error' }}>
                          Удалить
                        </ListItemText>
                      </MenuItem>
                    </Menu>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage='Рейтингов на странице'
        rowsPerPageOptions={[25, 50]}
        component='div'
        count={store.total}
        rowsPerPage={store.limit}
        page={store.page}
        onPageChange={(_, page) => store.setPage(page)}
        onRowsPerPageChange={store.setRowsPerPage}
        sx={{ mb: 3 }}
      />
    </div>
  );
});

const formatDate = (date: Date) => dayjs(date).format('DD MMMM YYYY');

export default RatingPage;
