import React from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  AdminDesignedLayoutRoutes,
  AdminLoginRoute,
  AdminRoutes,
  ExtraAdminRoutes,
  HomeRoute,
  TestRoutes,
} from '@app/routes';
import GlobalConfirmPopup from '@app/components/popups/GlobalConfirmPopup';

import globalAppStore from '@app/stores/globalAppStore';
import { AppRoute } from '@app/constants/common';

import './App.css';

const App = observer(() => {
  if (globalAppStore.isInitApp) {
    return <span>loading ...</span>;
  }

  return (
    <div className='app'>
      <Router>
        {globalAppStore.token ? (
          <Switch>
            <Redirect from={AppRoute.LOGIN} to={AppRoute.VISITS} />
            {AdminDesignedLayoutRoutes()}
            {AdminRoutes()}
            {ExtraAdminRoutes()}
            {TestRoutes()}
            <Redirect to={AppRoute.VISITS} />
          </Switch>
        ) : (
          <Switch>
            {AdminLoginRoute()}
            <Redirect from='/admin' to={AppRoute.LOGIN} />
            {HomeRoute()}
            <Redirect to={AppRoute.HOME} />
          </Switch>
        )}
      </Router>
      <GlobalConfirmPopup />
    </div>
  );
});

export default App;
