import React from 'react';
import dayjs from 'dayjs';
import { Button, Grid, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import SiteSelect from '@app/pages/admin/ReviewsPage/components/SiteSelect';
import { Search, CancelOutlined } from '@mui/icons-material';

import { getReviews } from '@app/api/review';
import type { ISite } from '@app/interfaces/site.interface';
import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IReview } from '@app/interfaces/review.interface';

type Props = {
  site: ISite | null;
  clinic: IClinic | null;
  date: Date | null;
  setSite: (site: ISite | null) => void;
  setClinic: (clinic: IClinic | null) => void;
  setDate: (date: Date | null) => void;
  setReviews: (reviews: IReview[]) => void;
  setTotal: (total: number) => void;
  fetchReviews: () => void;
};

function FilterPanel({
  site,
  clinic,
  date,
  setSite,
  setClinic,
  setDate,
  setReviews,
  setTotal,
  fetchReviews,
}: Props) {
  const [isFilter, setIsFilter] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const onSearch = async () => {
    setIsLoading(true);

    const { reviews, total } = await getReviews(0, 0, {
      site: site ? site.id : null,
      clinic: clinic ? clinic.id : null,
      date: date ? dayjs(date).format('DD-MM-YYYY') : null,
    });

    setIsFilter(true);
    setReviews(reviews);
    setTotal(total);
    setIsLoading(false);
  };

  const onClear = () => {
    setIsFilter(false);

    setSite(null);
    setClinic(null);
    setDate(null);

    fetchReviews();
  };

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={3}>
        <SiteSelect site={site} sites={[]} setSite={setSite} size='small' />
      </Grid>

      <Grid item sx={{ mt: '6px' }}>
        <DatePicker
          label='Дата отзыва'
          value={date ? dayjs(date) : null}
          onChange={(e) => setDate(dayjs(e).toDate())}
          format='DD MMMM YYYY'
          slotProps={{
            textField: {
              size: 'small',
            },
          }}
        />
      </Grid>

      <Grid item sx={{ mt: '6px' }}>
        <Button
          variant='contained'
          size='medium'
          startIcon={<Search />}
          disabled={isLoading || !(site || clinic || date)}
          onClick={onSearch}
        >
          Найти
        </Button>
      </Grid>

      {isFilter && (
        <Grid item sx={{ mt: '6px' }}>
          <IconButton aria-label='delete' onClick={onClear} size='small'>
            <CancelOutlined color='error' sx={{ fontSize: 32 }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default FilterPanel;
