import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import type { ISite } from '@app/interfaces/site.interface';
import { NEW_SITE, NewSiteFormConfig } from '@app/constants/site';
import useForm from '@app/hooks/useForm';
import { addSite, updateSite } from '@app/api/sites';

type Props = {
  site?: ISite;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSuccess: () => void;
};

function AddSiteButton({ site = NEW_SITE, isOpen, onOpen, onClose, onSuccess }: Props) {
  const { item, setItem, changeInput } = useForm<typeof NEW_SITE>(site);

  React.useEffect(() => {
    setItem(site);
  }, [site, setItem]);

  const onAddButtonClick = async () => {
    if (item?.id) {
      await updateSite(item);
    } else {
      await addSite(item);
    }

    onSuccess();
    onClose();
  };

  const isValidForm = () => {
    return NewSiteFormConfig.filter(({ required }) => required).every(({ field }) =>
      Boolean(item[field])
    );
  };

  return (
    <>
      <Button variant='outlined' onClick={onOpen}>
        Добавить Платформу
      </Button>

      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='sm'>
        <DialogTitle>Платформа</DialogTitle>

        <DialogContent>
          {NewSiteFormConfig.map(({ label, field, required }, idx) => {
            return (
              <TextField
                key={field}
                autoFocus={idx === 0}
                margin='dense'
                id={field}
                label={label}
                fullWidth
                required={required}
                value={item[field]}
                onChange={changeInput(field)}
              />
            );
          })}
        </DialogContent>

        <DialogActions>
          <Button onClick={onAddButtonClick} disabled={!isValidForm()}>
            {site?.id ? 'Сохранить' : 'Добавить'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddSiteButton;
