import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import DoctorCard from '@app/pages/clinicPage/components/doctorCard/DoctorCard';

import type { IIdentReception } from '@app/interfaces/ident.reception.interface';
import type { IOperator } from '@app/interfaces/operator.interface';
import type { IVisit } from '@app/interfaces/visit.interface';
import type { IDoctor } from '@app/interfaces/doctor.interface';
import { saveReceptions } from '@app/api';
import { deleteDoctor, fetchDoctorsByClinic, updateDoctor } from '@app/api/doctor';
import { fetchIdentReceptions } from '@app/utils/ident';
import { getIdentStaffName } from '@app/utils/visit';

type FetchVisitsPropsTypes = {
  date: Dayjs;
  operator: IOperator;
  clinicId: number;
  visits: IVisit[];
  onSuccess: () => void;
};

enum View {
  visits = 'visits',
  doctors = 'doctors',
}

const FetchVisits = ({ date, operator, clinicId, visits, onSuccess }: FetchVisitsPropsTypes) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const [receptions, setReceptions] = React.useState<IIdentReception[]>([]);
  const [doctors, setDoctors] = React.useState<IDoctor[]>([]);
  const [selectedReceptions, setSelectedReceptions] = React.useState<IIdentReception[]>([]);
  const [branchNameFilter, setBranchNameFilter] = React.useState('');
  const [smsMessageFilter, setSmsMessageFilter] = React.useState(false);
  const [view, setView] = React.useState<View>(View.visits);

  const branchNames = React.useMemo(() => {
    if (!receptions?.length) return [];

    return Array.from(new Set(receptions.map((reception) => reception.companyBranchName)));
  }, [receptions]);

  const filteredReceptions = React.useMemo(() => {
    if (!branchNameFilter && !smsMessageFilter) return receptions;

    return receptions.filter((reception) => {
      if (branchNameFilter && !smsMessageFilter) {
        return reception.companyBranchName === branchNameFilter;
      }

      const hasSmsMessage =
        reception.comment.toLowerCase().includes('смс') ||
        reception.receptionComment.toLowerCase().includes('смс') ||
        reception.additionalComment.toLowerCase().includes('смс');

      if (!branchNameFilter) return hasSmsMessage;

      return reception.companyBranchName === branchNameFilter && hasSmsMessage;
    });
  }, [receptions, branchNameFilter, smsMessageFilter]);

  const fetchDoctors = React.useCallback(() => {
    fetchDoctorsByClinic(clinicId).then((doctors) => setDoctors(doctors || []));
  }, [clinicId]);

  React.useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setView(View.visits), 500);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen) {
      setIsLoading(true);

      fetchDoctors();

      fetchIdentReceptions(date)
        .then((data) => setReceptions(data))
        .catch()
        .finally(() => setIsLoading(false));
    }
  }, [clinicId, date, isOpen, fetchDoctors]);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
    clearData();
  };

  const clearData = () =>
    setTimeout(() => {
      setReceptions([]);
      setSelectedReceptions([]);
    }, 500);

  const addReceptions = async () => {
    await saveReceptions(date, clinicId, operator, selectedReceptions);
    onSuccess();
    handleClose();
  };

  const isSelected = (reception: IIdentReception) =>
    !!selectedReceptions.find((rec) => rec.id === reception.id);

  const isAdded = (reception: IIdentReception) =>
    !!visits.find((visit) => visit.receptionId === reception.id);

  const toggleSelected = (reception: IIdentReception) => {
    if (isSelected(reception)) {
      setSelectedReceptions(selectedReceptions.filter((rec) => rec.id !== reception.id));
    } else {
      setSelectedReceptions([...selectedReceptions, reception]);
    }
  };

  const toggleView = (e: React.MouseEvent<HTMLElement>, newView: View) => setView(newView);

  return (
    <>
      <Button variant='outlined' onClick={handleOpen}>
        Загрузить
      </Button>

      <Dialog open={isOpen} onClose={handleClose} maxWidth='xl' fullWidth={true}>
        <DialogTitle>
          <Typography variant='h4'>IDENT визиты</Typography>
        </DialogTitle>

        <DialogContent>
          {isLoading ? (
            <>
              <Skeleton animation='wave' height={66} />
              <Skeleton animation='wave' height={66} />
              <Skeleton animation='wave' height={66} />
            </>
          ) : (
            <>
              <Box sx={{ marginTop: 1, display: 'flex', alignItems: 'center' }}>
                <FormControl sx={{}}>
                  <InputLabel id='clinic-select'>Филиал</InputLabel>
                  <Select
                    labelId='branch-select'
                    id='branch'
                    label='Филиал'
                    value={branchNameFilter}
                    onChange={(val) => setBranchNameFilter(val.target.value)}
                    sx={{ minWidth: '300px' }}
                  >
                    <MenuItem value={''} divider dense>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>Все</Typography>
                      </Box>
                    </MenuItem>

                    {branchNames.map((name) => (
                      <MenuItem key={name} value={name} divider dense>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography>{name}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box sx={{ width: '40px' }} />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={() => setSmsMessageFilter(!smsMessageFilter)}
                        checked={smsMessageFilter}
                        sx={{ marginRight: 2 }}
                      />
                    }
                    label='СМС'
                  />
                </FormGroup>

                <Box sx={{ marginRight: 'auto' }} />

                <ToggleButtonGroup
                  color='primary'
                  value={view}
                  exclusive
                  onChange={toggleView}
                  aria-label='View'
                >
                  <ToggleButton value={View.visits}>Ident Визиты</ToggleButton>
                  <ToggleButton value={View.doctors}>Доктора</ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {view === View.visits ? (
                <>
                  <TableContainer component={Paper} sx={{ margin: '24px 0 0' }}>
                    <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                      <TableHead>
                        <TableRow>
                          <TableCell component='th'>Филиал</TableCell>
                          <TableCell component='th'>Пациент</TableCell>
                          <TableCell component='th' align='right'>
                            Визит
                          </TableCell>
                          <TableCell component='th'>Доктор</TableCell>
                          <TableCell component='th'>Комментарий</TableCell>
                          <TableCell component='th'>Комментарий</TableCell>
                          <TableCell component='th'></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {(filteredReceptions || []).map((reception) => {
                          const isVisitAdded = isAdded(reception);

                          const identStaffFio = getIdentStaffName(reception.staff);
                          const respectDoctor = doctors.find(
                            (doctor) => doctor.fio.toLowerCase() === identStaffFio.toLowerCase()
                          );

                          if (respectDoctor) {
                            reception.doctorid = respectDoctor.id;
                          }

                          return (
                            <TableRow
                              key={reception.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: isVisitAdded ? 'aliceblue' : 'inherit',
                              }}
                            >
                              <TableCell>
                                {reception.companyBranchName ? (
                                  <Typography variant='subtitle2' fontWeight={400} noWrap>
                                    {reception.companyBranchName}
                                  </Typography>
                                ) : null}
                                {reception.companyAddress ? (
                                  <Typography variant='subtitle2' fontWeight={300} noWrap>
                                    {reception.companyAddress}
                                  </Typography>
                                ) : null}
                              </TableCell>

                              <TableCell>
                                <Typography variant='h6' fontWeight={400} noWrap>
                                  {getFullName(reception)}
                                </Typography>
                                <Typography variant='subtitle2' fontWeight={300}>
                                  тел.: {reception.userPhone}
                                </Typography>
                              </TableCell>

                              <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
                                <Typography variant='subtitle2' fontWeight={400} noWrap>
                                  {formatDate(reception.userReceptionAppeared)}
                                </Typography>
                                <Typography variant='subtitle2' fontWeight={300} noWrap>
                                  {formatTime(reception.receptionStarted)} -{' '}
                                  {formatTime(reception.receptionEnded)}
                                </Typography>
                              </TableCell>

                              <TableCell sx={{ minWidth: '250px' }}>
                                {reception.staff ? (
                                  <Typography
                                    variant='subtitle2'
                                    fontWeight={respectDoctor ? 'bold' : 400}
                                    color={respectDoctor ? 'green' : 'initial'}
                                  >
                                    {identStaffFio}
                                  </Typography>
                                ) : null}
                              </TableCell>

                              <TableCell sx={{ minWidth: '200px' }}>
                                {reception.receptionComment ? (
                                  <Typography variant='subtitle2' fontWeight={400}>
                                    {reception.receptionComment}
                                  </Typography>
                                ) : null}
                              </TableCell>

                              <TableCell>
                                {reception.comment ? (
                                  <Typography variant='subtitle2' fontWeight={400}>
                                    {reception.comment}
                                  </Typography>
                                ) : null}
                                {reception.additionalComment ? (
                                  <Typography variant='subtitle2' fontWeight={300}>
                                    {reception.additionalComment}
                                  </Typography>
                                ) : null}
                              </TableCell>

                              <TableCell align='center'>
                                <Checkbox
                                  inputProps={{ 'aria-label': 'Checkbox' }}
                                  color='primary'
                                  checked={isVisitAdded || isSelected(reception)}
                                  disabled={isVisitAdded}
                                  onChange={() => toggleSelected(reception)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <DialogActions sx={{ padding: 3 }}>
                    <Button
                      onClick={addReceptions}
                      disabled={!selectedReceptions.length}
                      variant='contained'
                    >
                      Добавить
                    </Button>
                  </DialogActions>
                </>
              ) : null}

              {view === View.doctors ? (
                <Box sx={{ margin: '24px 0 0' }}>
                  {doctors.map((doctor) => (
                    <>
                      <DoctorCard
                        key={doctor.id}
                        doctor={doctor}
                        onEdit={(doctor) => updateDoctor(doctor).then(fetchDoctors)}
                        onDelete={(doctor) => deleteDoctor(doctor).then(fetchDoctors)}
                      />
                    </>
                  ))}
                </Box>
              ) : null}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const getFullName = ({ userName, userPatronymic, userSurname }: IIdentReception) =>
  [userSurname, userName, userPatronymic].filter(Boolean).join(' ');

const formatDate = (date: Date) => dayjs.utc(date).format('DD MMMM YYYY');
const formatTime = (date: Date) => dayjs.utc(date).format('HH:mm');

export default FetchVisits;
