import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { doDelete, doGet, doPatch, doPost } from '@app/api';
import { IDoctor } from '@app/interfaces/doctor.interface';

export const fetchDoctorsByClinic = async (clinicId: number) => {
  try {
    const result = await doGet(`/api/doctor`, {
      params: {
        clinicId,
      },
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const addDoctor = async (clinicId: number, doctor: Partial<IDoctor>) => {
  try {
    const result = await doPost('/api/doctor', {
      clinicId,
      doctor,
    });
    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateDoctor = async (doctor: Partial<IDoctor>) => {
  try {
    const result = await doPatch(`/api/doctor/${doctor.id}`, {
      doctor,
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteDoctor = async (doctor: Partial<IDoctor>) => {
  try {
    const result = await doDelete(`/api/doctor/${doctor.id}`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};
