import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { ReactComponent as LogoIcon } from '../../../../../assets/images/logo.svg';
import { ReactComponent as UseIcon } from '../../../../../assets/images/user.svg';

import globalAppStore from '@app/stores/globalAppStore';
import clinicsStore from '@app/stores/clinics-store';

import useToggle from '@app/hooks/useToggle';
import type { IClinic } from '@app/interfaces/clinic.interface';

import './header.css';

const Header = observer(({ withClinics }: { withClinics?: boolean }) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isOpen, doOpen, doClose] = useToggle();

  React.useEffect(() => {
    clinicsStore.fetchClinics();
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelect = (clinic: IClinic) => {
    clinicsStore.setClinic(clinic);
    doClose();
  };

  return (
    <>
      <AppBar position='sticky'>
        <Container maxWidth='xl'>
          <Toolbar disableGutters sx={{ width: '100%', minHeight: { md: '66px' } }}>
            <Link to='/' style={{ paddingTop: '8px' }}>
              <LogoIcon />
            </Link>

            <Button
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'center',
                flex: 1,
                color: 'white',
              }}
              variant='text'
              onClick={doOpen}
            >
              {clinicsStore.clinic ? (
                <>
                  <Typography
                    variant='h2'
                    sx={{
                      fontFamily: 'Raleway',
                      fontSize: '30px',
                      fontWeight: 600,
                      marginRight: 2,
                      textTransform: 'initial',
                    }}
                  >
                    {clinicsStore.clinic?.name}
                  </Typography>

                  <Typography
                    variant='h3'
                    sx={{
                      fontFamily: 'Raleway',
                      fontSize: '20px',
                      fontWeight: 600,
                      textTransform: 'initial',
                    }}
                  >
                    {clinicsStore.clinic?.address}
                  </Typography>
                </>
              ) : (
                <Typography>Не выбрана Клиника</Typography>
              )}
            </Button>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title='Меню администратора'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <UseIcon />

                  <Typography
                    variant='h6'
                    noWrap
                    sx={{
                      mr: 2,
                      display: { xs: 'none', md: 'flex' },
                      fontSize: '20px',
                      fontWeight: 600,
                      color: 'white',
                      textDecoration: 'none',
                    }}
                  >
                    {globalAppStore.loggedUser?.name}
                  </Typography>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={globalAppStore.logOut}>
                  <Typography textAlign='center'>Выйти</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog open={isOpen} onClose={doClose} fullWidth={true} maxWidth='sm'>
        <DialogTitle>Клиники</DialogTitle>

        <DialogContent>
          <List>
            {clinicsStore.medodsClinics.map((clinic) => {
              return (
                <ListItem key={clinic.id} disablePadding onClick={() => {}}>
                  <ListItemButton onClick={() => handleSelect(clinic)}>
                    <ListItemText primary={clinic.name} secondary={clinic.address} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default Header;
