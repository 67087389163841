import { doPost } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { IVisit } from '@app/interfaces/visit.interface';

export const sendCountedSms = async (visit: IVisit) => {
  try {
    const result = await doPost(`/api/visit/${visit.slug}/counted-sms`, {});

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const sendSelectedVisitsSms = async (clinicId: number, visitIds: string[]) => {
  try {
    const result = await doPost(`/api/visit/${clinicId}/visits/sms`, {
      visits: visitIds,
    });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};
