import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import clinicsStore from '@app/stores/clinics-store';
import FormStore from '@app/stores/common/form-store';

import { getSites } from '@app/api/sites';
import { addRating, deleteRating, getRatings, updateRating } from '@app/api/rating';
import type { IRating } from '@app/interfaces/rating.interface';
import type { ISite } from '@app/interfaces/site.interface';

const NEW_RATING = {
  site: null,
  clinic: null,
  ratingDate: new Date(),
  rating: 5.0,
  comment: '',
};

class RatingPageStore extends FormStore<IRating> {
  ratings: IRating[] = [];
  sites: ISite[] = [];

  constructor() {
    super(NEW_RATING);

    makeObservable(this, {
      ratings: observable,
      sites: observable,
      setRatings: action,
      setSites: action,
      isValid: computed,
      fetchRatings: flow,
      fetchSites: flow,
      saveRating: flow,
      deleteRating: flow,
    });

    reaction(
      () => [clinicsStore.clinic, this.item.site],
      () => this.fetchRatings()
    );

    this.fetchSites();
  }

  setRatings(ratings: IRating[]) {
    this.ratings = ratings;
  }

  setSites(sites: ISite[]) {
    this.sites = sites;
  }

  get isValid() {
    return this.item.site && this.item.ratingDate && this.item.rating >= 0 && this.item.rating <= 5;
  }

  *fetchRatings() {
    if (!this.item.site || !clinicsStore.clinic) {
      return;
    }

    this.setIsLoading(true);

    const { ratings, total } = yield getRatings(
      this.item.site,
      clinicsStore.clinic,
      this.limit,
      this.page * this.limit
    );
    this.setRatings(ratings);
    this.setTotal(total);
    this.setIsLoading(false);
  }

  *fetchSites() {
    const sites: ISite[] = yield getSites();

    this.setSites(sites);
  }

  *saveRating() {
    this.setIsLoading(true);

    const payload = {
      ...this.item,
      clinic: clinicsStore.clinic!,
    };

    if (this.item.id) {
      yield updateRating(payload);
    } else {
      yield addRating(payload);
    }

    yield this.fetchRatings();

    this.closeEditor();

    this.setIsLoading(false);
  }

  *deleteRating(rating: IRating) {
    yield deleteRating(rating);
    yield this.fetchRatings();
  }
}

export default RatingPageStore;
