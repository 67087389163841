import { NavLink } from 'react-router-dom';
import { AppRoute } from '@app/constants/common';
import { Box, Button } from '@mui/material';
import React from 'react';

function NavigationBox() {
  return (
    <Box>
      <NavLink to={AppRoute.ANALYTICS} className='menu-item'>
        <Button>Аналитика</Button>
      </NavLink>
      <NavLink to={AppRoute.REVIEWS} className='menu-item'>
        <Button>Отзывы</Button>
      </NavLink>
      <NavLink to={AppRoute.RATING} className='menu-item'>
        <Button>Рейтинг</Button>
      </NavLink>
      <NavLink to={AppRoute.SITES} className='menu-item'>
        <Button>Платформы</Button>
      </NavLink>
    </Box>
  );
}

export default NavigationBox;
