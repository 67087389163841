import type { IClinic } from '@app/interfaces/clinic.interface';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import dayjs, { Dayjs } from 'dayjs';
import { doGet } from '@app/api';

export const getClinicFullAddress = (clinic: IClinic): string =>
  `г.${clinic.city}, ${clinic.address}`;

export const getClinicAcronym = (clinic: IClinic): string => {
  return clinic.name.slice(0, 2).toUpperCase();
};

export const getClinic = async (clinicId: number) => {
  try {
    const result = await doGet(`/api/clinics/${clinicId}`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchClinics = async () => {
  try {
    const result = await doGet('/api/clinics', {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchClinicVisit = async (clinicId: number, visitedAt: Dayjs) => {
  try {
    const result = await doGet(`/api/clinics/${clinicId}/visits`, {
      params: { visitedAt: dayjs(visitedAt).format('DD-MM-YYYY') },
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};
