export enum NewVisitField {
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  PHONE = 'phone',
  COMMENT = 'comment',
}

export const EMPTY_VISIT = {
  [NewVisitField.FIRSTNAME]: '',
  [NewVisitField.LASTNAME]: '',
  [NewVisitField.PHONE]: '',
  [NewVisitField.COMMENT]: '',
};

export const NewVisitFormConfig = [
  { label: 'Имя', field: NewVisitField.FIRSTNAME },
  { label: 'Фамилия', field: NewVisitField.LASTNAME },
  { label: 'Телефон', field: NewVisitField.PHONE },
  { label: 'Телефон', field: NewVisitField.COMMENT },
];
