import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  CircularProgress,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IOperator } from '@app/interfaces/operator.interface';
import { doDelete, doPatch, doPost } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import {
  EMPTY_CLINIC_OPERATOR,
  NewClinicOperatorInputConfig,
} from '@app/constants/clinic';
import globalAppStore from '@app/stores/globalAppStore';
import MoreIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import useDropdownMenu from '@app/hooks/useDropdownMenu';

function ClinicOperatorsList({
  clinic,
  setClinic,
}: {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}) {
  const [operator, setOperator] = React.useState(EMPTY_CLINIC_OPERATOR);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [
    anchorsList,
    openDropdownMenu,
    closeDropdownMenu,
    closeAllDropdownMenu,
  ] = useDropdownMenu();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUpdatedOperator, setIsUpdatedOperator] = React.useState(false);

  const activeOperators = React.useMemo(
    () => clinic.operators.filter((operator) => !operator.isDeleted),
    [clinic]
  );

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickOpen = () => {
    setOperator(EMPTY_CLINIC_OPERATOR);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleUpdateOperatorClick = async (
    operator: Pick<IOperator, 'id' | 'name' | 'email'>
  ) => {
    closeAllDropdownMenu();
    setIsUpdatedOperator(false);
    setOperator({ ...operator, password: '' });
    setOpen(true);
  };

  const changeOperator =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsUpdatedOperator(true);
      setOperator({
        ...operator,
        [field]: e.target.value,
      });
    };

  const addOperator = async () => {
    setIsLoading(true);

    const { id, ...rest } = operator;
    const result = await doPost(`/api/clinics/${clinic.id}/operator`, {
      operator: rest,
    });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      if (result.data?.operator) {
        setClinic({
          ...clinic,
          operators: [...clinic.operators, result.data.operator],
        });
      }
      setOperator(EMPTY_CLINIC_OPERATOR);
      handleClose();
    }

    setIsLoading(false);
  };

  const updateOperator = async () => {
    setIsLoading(true);

    const result = await doPatch(
      `/api/clinics/${clinic.id}/operator/${operator.id}`,
      {
        operator: Object.entries(operator).reduce((res, [key, value]) => {
          if (key === 'id') {
            return res;
          }

          if (Boolean(value)) {
            Object.assign(res, { [key]: value.trim() });
          }

          return res;
        }, {}),
      }
    );

    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      setClinic(result.data);
      setOperator(EMPTY_CLINIC_OPERATOR);
      handleClose();
    }

    setIsLoading(false);
  };

  const deleteOperator = async (
    operator: Pick<IOperator, 'id' | 'name' | 'email'>
  ) => {
    closeAllDropdownMenu();
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление оператора',
      text: `${operator.name}`,
      subtext: `${operator.email}`,
      confirmButtonTitle: 'Удалить',
      onConfirm: async () => {
        const result = await doDelete(
          `/api/clinics/${clinic.id}/operator/${operator.id}`,
          {}
        );

        if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
          setClinic(result.data);
        }
      },
    });
  };

  const isFormValid = () => {
    const { id, ...requiredField } = operator;

    return (
      (Boolean(operator.id) && isUpdatedOperator) ||
      Object.values(requiredField).every(Boolean)
    );
  };

  return (
    <>
      <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
        {activeOperators.map(({ name, email, id }: IOperator, idx) => (
          <ListItem
            key={id}
            alignItems='flex-start'
            secondaryAction={
              <Box>
                <IconButton
                  size='large'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={openDropdownMenu(idx)}
                  color='inherit'
                >
                  <MoreIcon />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorsList?.[idx] || null}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorsList?.[idx])}
                  onClose={closeDropdownMenu(-1)}
                >
                  <MenuItem
                    onClick={() =>
                      handleUpdateOperatorClick({ name, email, id })
                    }
                  >
                    <ListItemIcon>
                      <EditIcon fontSize='small' color='primary' />
                    </ListItemIcon>
                    <ListItemText>Редактировать</ListItemText>
                  </MenuItem>

                  <Divider />

                  <MenuItem onClick={() => deleteOperator({ name, id, email })}>
                    <ListItemIcon>
                      <DeleteIcon fontSize='small' color='error' />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ color: 'error' }}>
                      Удалить
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            }
          >
            <ListItemAvatar>
              <Avatar alt={name}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={
                <Typography
                  sx={{ display: 'inline' }}
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  {email}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>

      <Button onClick={handleClickOpen}>Добавить оператора</Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Оператор</DialogTitle>
        <DialogContent>
          {NewClinicOperatorInputConfig.map(
            ({ label, field, ...props }, idx) => {
              if (field === 'password') {
                return (
                  <FormControl
                    key={field}
                    sx={{ mt: 1.5, width: '100%' }}
                    variant='outlined'
                  >
                    <InputLabel htmlFor='password'>{label}</InputLabel>
                    <OutlinedInput
                      id='password'
                      type={showPassword ? 'text' : 'password'}
                      value={operator[field]}
                      onChange={changeOperator(field)}
                      autoComplete='off'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label={label}
                    />
                  </FormControl>
                );
              }

              return (
                <TextField
                  key={field}
                  autoFocus={idx === 0}
                  margin='dense'
                  id={field}
                  label={label}
                  fullWidth
                  value={operator[field]}
                  onChange={changeOperator(field)}
                  {...props}
                />
              );
            }
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={operator.id ? updateOperator : addOperator}
            disabled={!isFormValid() || isLoading}
          >
            {isLoading ? (
              <CircularProgress size={16} color='secondary' />
            ) : operator.id ? (
              'Обновить'
            ) : (
              'Добавить'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClinicOperatorsList;
