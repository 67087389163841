import React from 'react';
import { Box, List, ListItem, Switch, Typography } from '@mui/material';
import { IClinic } from '@app/interfaces/clinic.interface';
import { updateClinic } from '@app/api/clinic';

type Props = {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
};

function IdentToggler({ clinic, setClinic }: Props) {
  const handleToggle = async () => {
    const res = await updateClinic({ ...clinic, isIdent: !clinic.isIdent });

    setClinic(res.data);
  };

  return (
    <Box>
      <Typography variant='h6'>Ident:</Typography>

      <List dense={true}>
        <ListItem disablePadding>
          <Switch
            edge='start'
            onChange={handleToggle}
            checked={clinic.isIdent}
            sx={{ marginRight: 2 }}
            disabled={false}
          />
        </ListItem>
      </List>
    </Box>
  );
}

export default IdentToggler;
