import React from 'react';
import PageTitle from '@app/components/pageTitle/PageTitle';
import { Button } from '@mui/material';
import { fetchIdentPersons, fetchIdentStaffs, fetchIdentTreatDoctors } from '@app/utils/ident';


function TestPage() {
  const getDoctors = () => fetchIdentTreatDoctors();

  return (
    <div className='content'>
      <PageTitle
        title='Test'

      />

      <Button onClick={() => getDoctors()}>Get Doctors</Button>

      <br/>

      <Button onClick={() => fetchIdentStaffs()}>Get Staffs</Button>

      <br/>

      <Button onClick={() => fetchIdentPersons()}>Get Persons</Button>

    </div>
  );
}

export default TestPage;
