import React from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Rating,
  Select,
  SelectChangeEvent,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import PageTitle from '@app/components/pageTitle/PageTitle';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import DownloadIcon from '@mui/icons-material/Download';

import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IClient } from '@app/interfaces/client.interface';
import type { IFeedback } from '@app/interfaces/feedback.interface';
import { fetchClinics } from '@app/utils/clinic';
import { doDelete, fetchFeedbacks, sendSms, updateFeedback } from '@app/api';
import 'dayjs/locale/ru';
import globalAppStore from '@app/stores/globalAppStore';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { downloadClinicFeedbackReport } from '@app/api/clinic';

dayjs.locale('ru');

const AdminFeedbacksPage = observer(() => {
  const [clinic, setClinic] = React.useState<IClinic>();
  const [clinics, setClinics] = React.useState<IClinic[] | []>([]);
  const [feedbacks, setFeedbacks] = React.useState<IFeedback[] | []>([]);
  const [limit, setLimit] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [isLoadingId, setIsLoadingId] = React.useState<number | null>(null);
  const [isDeletingId, setIsDeletingId] = React.useState<number | null>(null);
  const [isDownloading, setIsDownloading] = React.useState(false);

  React.useEffect(() => {
    fetchClinics().then((clinics) => setClinics(clinics));
  }, []);

  React.useEffect(() => {
    if (clinic) {
      fetchFeedbacks(clinic.id, limit, page * limit).then((data) => {
        setFeedbacks(data?.feedbacks || []);
        setTotal(data?.feedbacksCount || 0);
      });
    }
  }, [clinic, limit, page]);

  const selectClinic = (e: SelectChangeEvent) => {
    const clinic = clinics.find((c) => c.id === +e.target.value);
    setClinic(clinic);
  };

  const handleFeedbackChange =
    (feedback: IFeedback) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedFeedback = await updateFeedback(feedback.id, event.target.checked);

      if (updatedFeedback) {
        const idx = feedbacks.findIndex((f) => f.id === updatedFeedback.id);
        setFeedbacks([
          ...feedbacks.slice(0, idx),
          Object.assign(feedback, updatedFeedback),
          ...feedbacks.slice(idx + 1),
        ]);
      }
    };

  const handleDeleteFeedback = async (feedback: IFeedback) => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление отзыва',
      text: `${getFullName(feedback.visit.client)}`,
      subtext: `тел.: ${feedback.visit.client.phone}`,
      confirmButtonTitle: 'Удалить',
      onConfirm: async () => {
        setIsDeletingId(feedback.id);

        const result = await doDelete(`/api/feedbacks/${feedback.id}`, {});

        if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
          fetchFeedbacks(clinic!.id, limit, page * limit).then((data) => {
            setFeedbacks(data?.feedbacks || []);
            setTotal(data?.feedbacksCount || 0);
          });
        }
        setIsDeletingId(null);
      },
    });
  };

  const handleSendSms = async (feedback: IFeedback) => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Отправка СМС',
      text: `${getFullName(feedback.visit.client)}`,
      subtext: `тел.: ${feedback.visit.client.phone}`,
      confirmButtonTitle: 'Отправить повторно',
      onConfirm: async () => {
        setIsLoadingId(feedback.id);

        await sendSms(feedback.visit);

        fetchFeedbacks(clinic!.id, limit, page * limit).then((data) => {
          setFeedbacks(data?.feedbacks || []);
          setTotal(data?.feedbacksCount || 0);
        });

        setIsLoadingId(null);
      },
    });

    setIsLoadingId(null);
  };

  const handleChangePage = (event: unknown, page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadClick = () => {
    setIsDownloading(true);
    downloadClinicFeedbackReport(clinic!)
      .then()
      .finally(() => setIsDownloading(false));
  };

  if (!clinics.length) {
    return <span>loading ...</span>;
  }

  return (
    <div className='content'>
      <PageTitle title='Отзывы' />

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <FormControl fullWidth sx={{ width: '100%', maxWidth: '400px' }}>
          <InputLabel id='clinic-select'>Клиника</InputLabel>
          <Select
            labelId='clinic-select'
            id='clinic'
            label='Клиника'
            value={(clinic?.id || '').toString()}
            onChange={selectClinic}
          >
            {clinics.map((clinic) => (
              <MenuItem key={clinic.id} value={clinic.id}>
                {clinic.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {clinic ? (
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              onClick={handleDownloadClick}
              disabled={isDownloading}
              variant='contained'
              endIcon={<DownloadIcon />}
            >
              xls
            </Button>

            {isDownloading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        ) : null}
      </Box>

      <TableContainer component={Paper} sx={{ margin: '24px 0' }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>Пациент</TableCell>
              <TableCell align='right'>Дата визита</TableCell>
              <TableCell align='right'>Рейтинг</TableCell>
              <TableCell align='center'>
                <TaskAltIcon fontSize='small' />
              </TableCell>
              <TableCell>Отзыв</TableCell>
              <TableCell align='center'>СМС</TableCell>
              <TableCell align='right'>Дата отзыва</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks.map((feedback) => (
              <TableRow
                key={feedback.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  <Box>
                    <Typography variant='h6' fontWeight={400}>
                      {getFullName(feedback.visit.client)}
                    </Typography>
                    <Typography variant='subtitle2' fontWeight={300}>
                      тел.: {feedback.visit.client.phone}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
                  {formatDate(feedback.visit.visitedAt)}
                </TableCell>
                <TableCell align='right'>
                  <Rating name='read-only' value={feedback.rating} readOnly />
                </TableCell>
                <TableCell align='center'>
                  <Checkbox
                    inputProps={{ 'aria-label': 'Checkbox demo' }}
                    color='success'
                    checked={feedback.isFeedbackReceived}
                    onChange={handleFeedbackChange(feedback)}
                  />
                </TableCell>
                <TableCell sx={{ maxWidth: '350px', wordBreak: 'break-all' }}>
                  {feedback.doctor ? (
                    <NoMaxWidthTooltip
                      title={
                        <Typography>{`${feedback.doctor.fio} (${feedback.doctor.profession})`}</Typography>
                      }
                      placement='top'
                      arrow
                    >
                      <Button>{feedback.text}</Button>
                    </NoMaxWidthTooltip>
                  ) : (
                    feedback.text
                  )}
                </TableCell>
                <TableCell align='center'>
                  {isLoadingId === feedback.id ? (
                    <CircularProgress size={32} />
                  ) : (
                    <StyledBadge badgeContent={feedback.visit.sms.length} color='warning'>
                      <IconButton
                        aria-label='sms'
                        size='large'
                        color='primary'
                        onClick={() => handleSendSms(feedback)}
                      >
                        <SmsIcon />
                      </IconButton>
                    </StyledBadge>
                  )}
                </TableCell>
                <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
                  {formatDate(feedback.createdAt)}
                </TableCell>
                <TableCell>
                  <IconButton aria-label='delete' onClick={() => handleDeleteFeedback(feedback)}>
                    {isDeletingId === feedback.id ? (
                      <CircularProgress size={16} color='error' />
                    ) : (
                      <DeleteIcon color='error' />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage='Отзывов на странице'
        rowsPerPageOptions={[25, 50]}
        component='div'
        count={total}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mb: 3 }}
      />
    </div>
  );
});

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 10,
  },
}));

const getFullName = ({ firstname, lastname }: IClient) => `${lastname} ${firstname}`;

const formatDate = (date: Date) => dayjs(date).format('DD MMMM YYYY');

export default AdminFeedbacksPage;
