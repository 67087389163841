import dayjs from 'dayjs';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

import type { IClient } from '../interfaces/client.interface';
import type { IAppointment } from '@app/pages/admin/Integrations/Medods/interfaces/appointment.interface';
import { IVisit } from '@app/interfaces/visit.interface';

interface IProps {
  offset: number;
  appointments?: { data: IAppointment[]; totalItems: number };
  selectedAppointments: IAppointment[];
  setSelectedAppointments: (appointments: IAppointment[]) => void;
  visits: IVisit[];
}

function Appointments({
  appointments,
  offset,
  selectedAppointments,
  setSelectedAppointments,
  visits,
}: IProps) {
  const isSelected = (appointment: IAppointment) =>
    !!selectedAppointments.find((rec) => rec.id === appointment.id);

  const isAdded = (appointment: IAppointment) =>
    !!visits.find((rec) => rec.receptionId === appointment.id);

  const toggleSelected = (appointment: IAppointment) => {
    if (isSelected(appointment)) {
      setSelectedAppointments(selectedAppointments.filter((rec) => rec.id !== appointment.id));
    } else {
      setSelectedAppointments([...selectedAppointments, appointment]);
    }
  };

  return (
    <Paper>
      <TableContainer component={Paper} sx={{ margin: '24px 0' }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell component='th' width={40} align='center'>
                №
              </TableCell>
              <TableCell component='th'>Пациент</TableCell>

              <TableCell component='th' align='right'>
                Дата визита
              </TableCell>
              <TableCell component='th'>Тип приема</TableCell>
              <TableCell component='th'>Комментарий</TableCell>
              <TableCell component='th' width={80} align='center'>
                sms
              </TableCell>
              <TableCell component='th' width={80} align='center'>
                WApp
              </TableCell>
              <TableCell component='th' width={80} align='center' />
            </TableRow>
          </TableHead>

          <TableBody>
            {appointments?.data.map((appointment, idx) => {
              const isVisitAdded = isAdded(appointment);

              return (
                <TableRow
                  key={appointment.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: isVisitAdded ? 'aliceblue' : 'inherit',
                  }}
                >
                  <TableCell>
                    <Typography
                      variant='h5'
                      sx={{ width: '35px', marginRight: '8px', textAlign: 'right' }}
                    >
                      {idx + 1 + offset}.
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6' fontWeight={400} noWrap>
                      {getFullName(appointment.client)}
                    </Typography>
                    <Typography variant='subtitle2' fontWeight={300}>
                      {`+${appointment.client.phone}`}
                    </Typography>
                  </TableCell>
                  <TableCell align='right'>
                    <Typography variant='body1' fontWeight={400} noWrap>
                      {dayjs(appointment.date).format('DD MMM YYYY')}
                    </Typography>
                    <Typography variant='subtitle2' fontWeight={300}>
                      {appointment.time}
                    </Typography>
                  </TableCell>
                  <TableCell>{appointment.appointmentType.title}</TableCell>
                  <TableCell>{appointment.clientComment}</TableCell>
                  <TableCell align='center'>
                    {getIcon(appointment.client.denySmsNotifications)}
                  </TableCell>
                  <TableCell align='center'>
                    {getIcon(appointment.client.denyWhatsappMessages)}
                  </TableCell>
                  <TableCell align='center'>
                    <Checkbox
                      inputProps={{ 'aria-label': 'Checkbox' }}
                      color='primary'
                      checked={isVisitAdded || isSelected(appointment)}
                      disabled={isVisitAdded}
                      onChange={() => toggleSelected(appointment)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export const getFullName = ({ name, secondName, surname }: IClient) => {
  return [surname, name, secondName].filter(Boolean).join(' ');
};

const getIcon = (denyStatus: boolean) => {
  return denyStatus ? <DoDisturbOnIcon color={'error'} /> : <TaskAltIcon color={'success'} />;
};

export default Appointments;
