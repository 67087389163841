import React from 'react';
import { Container } from '@mui/material';
import bgPart from './bg-part.png';
import Header from '@app/layouts/admin/AdminDesignedLayout/components/header/Header';

interface IProps {
  children: React.ReactElement;
  className?: string;
}

function AdminDesignedLayout({ children }: IProps) {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        backgroundImage: `url(${bgPart})`,
        padding: 0,
        minHeight: '100vh',
      }}
    >
      <Header />

      <Container
        maxWidth='xl'
        sx={{
          marginTop: '24px',
          height: '100%',
          display: 'grid',
          gap: '20px',
        }}
      >
        {children}
      </Container>
    </Container>
  );
}

export default AdminDesignedLayout;
