import React from 'react';
import { List, ListItem, ListItemText, Switch, Typography } from '@mui/material';
import EditDoctorListButton from '@app/pages/clinicPage/components/editDoctorListButton/EditDoctorListButton';
import EditUrlButton from '@app/pages/clinicPage/components/editUrlButton/EditUrlButton';

import type { IClinic } from '@app/interfaces/clinic.interface';
import { toggleFeedbackSite } from '@app/api';
import { FEEDBACK_SITE_NAMES, LINK_CONFIG } from '@app/constants/clinic';

type Params = {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
};

function ClinicOuterSiteIdsList({ clinic, setClinic }: Params) {
  const { feedbackSites } = clinic;

  const onSwitchToggle = (siteName: string) => async () => {
    const updatedClinic = await toggleFeedbackSite(clinic.id, siteName);

    if (updatedClinic) {
      setClinic(updatedClinic);
    }
  };

  const isChecked = (name: string) => (feedbackSites || []).includes(name);

  return (
    <List dense={true} sx={{ width: 450 }}>
      {Object.keys(LINK_CONFIG).map((key) => {
        const { name, field } = LINK_CONFIG[key];

        return (
          <ListItem key={field} sx={{ borderBottom: '1px solid lightgrey' }}>
            <Switch
              edge='start'
              onChange={onSwitchToggle(key)}
              checked={isChecked(key)}
              sx={{ marginRight: 2 }}
              disabled={!isChecked(key) && !clinic[field]}
            />

            <ListItemText
              primary={<Typography variant='h6'>{name}</Typography>}
              secondary={clinic[field] || '-'}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />

            {key === FEEDBACK_SITE_NAMES.PRODOCTOROV ? (
              <EditDoctorListButton clinic={clinic} setClinic={setClinic} />
            ) : null}

            <EditUrlButton clinic={clinic} setClinic={setClinic} config={LINK_CONFIG[key]} />
          </ListItem>
        );
      })}
    </List>
  );
}

export default ClinicOuterSiteIdsList;
