import React from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import PageTitle from '@app/components/pageTitle/PageTitle';
import AddSiteButton from './components/AddSiteButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import globalAppStore from '@app/stores/globalAppStore';

import useToggle from '@app/hooks/useToggle';
import useDropdownMenu from '@app/hooks/useDropdownMenu';
import { deleteSite, getSites } from '@app/api/sites';
import type { ISite } from '@app/interfaces/site.interface';
import NavigationBox from '@app/pages/admin/components/NavigationBox';

function SitesPage() {
  const [sites, setSites] = React.useState<ISite[]>([]);
  const [isOpenEditor, openEditor, closeEditor] = useToggle(false);
  const [selectedSite, setSelectedSite] = React.useState<ISite>();
  const [anchorsList, openDropdownMenu, closeDropdownMenu, closeAllDropdownMenu] =
    useDropdownMenu();
  const [isDeletingId, setIsDeletingId] = React.useState<number>();

  const fetchSites = React.useCallback(() => {
    getSites().then((sites) => setSites(sites || []));
  }, []);

  React.useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  React.useEffect(() => {
    if (!isOpenEditor) {
      setSelectedSite(undefined);
    }
  }, [isOpenEditor]);

  const handleEdit = (site: ISite) => {
    setSelectedSite(site);
    closeAllDropdownMenu();
    openEditor();
  };

  const handleDelete = async (site: ISite) => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление платформы',
      text: 'Вы уверены, что хотите удалить Платформу?',
      subtext: '',
      confirmButtonTitle: 'Удалить',
      onConfirm: async () => {
        setIsDeletingId(site.id);
        await deleteSite(site);
        fetchSites();
        closeAllDropdownMenu();
        setIsDeletingId(undefined);
      },
    });
  };

  const handleSuccess = () => {
    fetchSites();
  };

  return (
    <div className='content'>
      <PageTitle
        title='Платформы'
        addButton={
          <AddSiteButton
            site={selectedSite}
            isOpen={isOpenEditor}
            onOpen={openEditor}
            onClose={closeEditor}
            onSuccess={handleSuccess}
          />
        }
        action={<NavigationBox />}
      />

      <Box sx={{ paddingBottom: 6 }}>
        <List disablePadding sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {sites.map((site, idx) => {
            return (
              <Box key={site.id}>
                <ListItem
                  sx={{
                    paddingLeft: 0,
                  }}
                >
                  <ListItemText primary={site.name} secondary={site.url} />

                  <ListItemSecondaryAction>
                    <IconButton
                      size='large'
                      aria-label='account of current user'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      onClick={openDropdownMenu(idx)}
                      color='inherit'
                    >
                      <MoreIcon />
                    </IconButton>

                    <Menu
                      id='menu-appbar'
                      anchorEl={anchorsList?.[idx] || null}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorsList?.[idx])}
                      onClose={closeDropdownMenu(-1)}
                    >
                      <MenuItem onClick={() => handleEdit(site)}>
                        <ListItemIcon>
                          <EditIcon fontSize='small' color='primary' />
                        </ListItemIcon>
                        <ListItemText>Редактировать</ListItemText>
                      </MenuItem>

                      <Divider />

                      <MenuItem
                        onClick={() => handleDelete(site)}
                        disabled={isDeletingId === site.id}
                      >
                        <ListItemIcon>
                          {isDeletingId === site.id ? (
                            <CircularProgress size={16} color='error' />
                          ) : (
                            <DeleteIcon fontSize='small' color='error' />
                          )}
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ color: 'error' }}>
                          Удалить
                        </ListItemText>
                      </MenuItem>
                    </Menu>
                  </ListItemSecondaryAction>
                </ListItem>

                {idx < sites.length - 1 && <Divider component='li' />}
              </Box>
            );
          })}
        </List>
      </Box>
    </div>
  );
}

export default SitesPage;
