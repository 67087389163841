import { doGet, doPut, doPost, doDelete } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { NEW_REVIEW } from '@app/constants/review';
import { IReview } from '@app/interfaces/review.interface';

type Params = {
  site?: number | null;
  clinic?: number | null;
  date?: string | null;
};
export const getReviews = async (limit: number, offset: number, { site, clinic, date }: Params) => {
  try {
    const result = await doGet('/api/review', {
      params: { limit, offset, site, clinic, date },
    });

    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const addReview = async (review: typeof NEW_REVIEW) => {
  try {
    const result = await doPost('/api/review', { review });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateReview = async (review: IReview) => {
  try {
    const result = await doPut(`/api/review/${review.id}`, { review });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteReview = async (review: IReview) => {
  try {
    await doDelete(`/api/review/${review.id}`, {});
  } catch (e) {
    console.log('e', e);
  }
};
