import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import StatusSelect from '@app/pages/admin/Integrations/Medods/components/StatusSelect';
import Appointments from '@app/pages/admin/Integrations/Medods/components/Appointments';

import clinicsStore from '@app/stores/clinics-store';
import VisitsPageStore from '@app/stores/pages/visitsPageStore';

import { doGet, saveReceptions } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import type { IOperator } from '@app/interfaces/operator.interface';
import type { IAppointment } from '@app/pages/admin/Integrations/Medods/interfaces/appointment.interface';
import { fetchClinicVisit } from '@app/utils/clinic';

const itemPerPage = 25;

const visitsStore = new VisitsPageStore();

const MedodsPage = observer(() => {
  const [date, setDate] = React.useState<Dayjs>(() => dayjs());
  const [status, setStatus] = React.useState('serviced');
  const [appointments, setAppointments] = React.useState<{
    data: IAppointment[];
    totalItems: number;
  }>();
  const [selectedAppointments, setSelectedAppointments] = React.useState<IAppointment[]>([]);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [operator, setOperator] = React.useState<IOperator>();

  const fetchVisits = React.useCallback(() => {
    if (clinicsStore.clinic) {
      fetchClinicVisit(clinicsStore.clinic.id, date).then((visits) =>
        visitsStore.setVisits(visits)
      );
    } else {
      visitsStore.setVisits([]);
    }
  }, [date]);

  React.useEffect(() => {
    fetchVisits();
  }, [date]);

  React.useEffect(() => {
    setOffset((page - 1) * itemPerPage);
  }, [page]);

  React.useEffect(() => {
    doGet(`/api/integrations/medods/appointments`, {
      params: {
        status,
        dateStart: dayjs(date).startOf('day').format('YYYY-MM-DD'),
        dateEnd: dayjs(date).add(1, 'day').startOf('day').format('YYYY-MM-DD'),
        offset,
        limit: itemPerPage,
      },
    })
      .then((result) => {
        if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS && result.data) {
          setAppointments(result.data);
        }
      })
      .catch((e) => console.log('e', e));
  }, [date, status, offset]);

  const clinicOperators = React.useMemo(
    () => clinicsStore.clinic?.operators?.filter((operator) => !operator.isDeleted) || [],
    [clinicsStore.clinic]
  );

  const selectOperator = (e: SelectChangeEvent) => {
    if (!clinicsStore.clinic) {
      return;
    }
    const operator = clinicsStore.clinic.operators.find((op) => {
      return op.id === e.target.value;
    });

    setOperator(operator);
  };

  const addAppointments = async () => {
    if (clinicsStore.clinic?.id && operator && selectedAppointments?.length) {
      console.log('selectedAppointments', selectedAppointments);

      const data = selectedAppointments.map((appointment) => ({
        id: appointment.id,
        userName: appointment.client.name,
        userSurname: appointment.client.surname,
        userPhoneString: appointment.client.phone,
        receptionComment: appointment.clientComment || '',
        date,
      }));
      await saveReceptions(date, clinicsStore.clinic.id, operator, data);
      fetchVisits();
    }
  };

  return (
    <div className='content'>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gap: 2,
          justifyContent: 'start',
          alignItems: 'center',
          gridTemplateColumns: 'max-content max-content 1fr max-content',
        }}
      >
        <DatePicker value={date} onChange={(val) => setDate(val as Dayjs)} format='DD MMMM YYYY' />

        <StatusSelect status={status} setStatus={setStatus} />

        <Box sx={{ display: 'grid', gridTemplateColumns: '260px 1fr', gap: '1rem' }}>
          <FormControl fullWidth sx={{ width: '100%' }}>
            <InputLabel id='operator-select'>Оператор</InputLabel>
            <Select
              labelId='operator-select'
              id='operator'
              label='Оператор'
              value={(operator?.id || '').toString()}
              onChange={selectOperator}
              disabled={!clinicOperators.length}
            >
              {clinicOperators.map((operator) => (
                <MenuItem key={operator.id} value={operator.id}>
                  {operator.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            size='large'
            variant='contained'
            disabled={!selectedAppointments?.length || !operator}
            onClick={addAppointments}
          >
            Добавить
          </Button>
        </Box>

        {appointments && appointments?.totalItems > itemPerPage ? (
          <Pagination
            page={page}
            count={Math.floor(appointments?.totalItems / itemPerPage)}
            onChange={(_event, page) => setPage(page)}
            variant='outlined'
            color='primary'
          />
        ) : null}
      </Box>

      <Appointments
        offset={offset}
        appointments={appointments}
        selectedAppointments={selectedAppointments}
        setSelectedAppointments={setSelectedAppointments}
        visits={visitsStore.visits}
      />
    </div>
  );
});

export default MedodsPage;
