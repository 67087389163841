import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import EditIcon from '@mui/icons-material/Edit';
import type { IClinic } from '@app/interfaces/clinic.interface';
import useToggle from '@app/hooks/useToggle';
import useClinic from '@app/hooks/useClinic';
import { updateClinic } from '@app/api/clinic';

type Props = {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
};

function SmsCountInfo({ clinic, setClinic }: Props) {
  const [isOpen, doOpen, doClose] = useToggle();
  const [changeClinic] = useClinic(clinic, setClinic);

  const handleSave = async () => {
    await updateClinic(clinic);

    doClose();
  };

  return (
    <>
      <Box>
        <Typography variant='h6'>SMS (отправлено / договор):</Typography>

        <List dense={true}>
          <ListItem
            disablePadding
            secondaryAction={
              <IconButton edge='end' aria-label='edit' onClick={doOpen} color='success'>
                <EditIcon />
              </IconButton>
            }
          >
            <ListItemAvatar sx={{ display: 'flex' }}>
              <SmsIcon color='primary' />
            </ListItemAvatar>
            <ListItemText
              primary={`${clinic.smsSentCount} / ${clinic.smsContractCount}`}
              primaryTypographyProps={{ color: 'text.primary' }}
            />
          </ListItem>
        </List>
      </Box>

      <Dialog open={isOpen} onClose={doClose}>
        <DialogTitle>SMS</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Отправлено'
            fullWidth
            variant='outlined'
            type='number'
            value={clinic.smsSentCount}
            onChange={changeClinic('smsSentCount')}
          />

          <TextField
            margin='dense'
            id='name'
            label='По договору'
            fullWidth
            variant='outlined'
            type='number'
            value={clinic.smsContractCount}
            onChange={changeClinic('smsContractCount')}
          />
        </DialogContent>

        <DialogActions sx={{ padding: '0 24px 24px' }}>
          <Button onClick={doClose}>Отмена</Button>

          <Button onClick={handleSave} variant='contained'>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SmsCountInfo;
