import dayjs from 'dayjs';
import type { IReview } from '@app/interfaces/review.interface';

export enum NewReviewField {
  DATE = 'reviewDate',
  RATING = 'rating',
  SITE = 'site',
  CLINIC = 'clinic',
  DOCTOR = 'doctor',
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  PHONE = 'phone',
  IS_RESPECT_CLIENT = 'isRespectClient',
  TEXT = 'text',
  COMMENT = 'comment',
}

export const NEW_REVIEW: Pick<IReview, NewReviewField> & Partial<Pick<IReview, 'id'>> = {
  [NewReviewField.DATE]: dayjs().toDate(),
  [NewReviewField.RATING]: 5,
  [NewReviewField.SITE]: null,
  [NewReviewField.CLINIC]: null,
  [NewReviewField.DOCTOR]: null,
  [NewReviewField.FIRSTNAME]: '',
  [NewReviewField.LASTNAME]: '',
  [NewReviewField.PHONE]: '',
  [NewReviewField.IS_RESPECT_CLIENT]: false,
  [NewReviewField.TEXT]: '',
  [NewReviewField.COMMENT]: '',
};

export const NewReviewClientFormConfig = [
  { label: 'Фамилия', field: NewReviewField.LASTNAME, required: true },
  { label: 'Имя', field: NewReviewField.FIRSTNAME, required: true },
  { label: 'Телефон', field: NewReviewField.PHONE, required: true },
];

export const NewReviewTextFormConfig = [
  { label: 'Отзыв', field: NewReviewField.TEXT, required: true, type: 'multiline' },
  { label: 'Комментарий', field: NewReviewField.COMMENT, type: 'multiline' },
];

export const RequiredFields = [
  NewReviewField.SITE,
  NewReviewField.CLINIC,
  NewReviewField.FIRSTNAME,
  NewReviewField.LASTNAME,
  NewReviewField.PHONE,
  NewReviewField.TEXT,
];
