import { doDelete, doGet, doPost, doPut } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { ISite } from '@app/interfaces/site.interface';
import { IRating } from '@app/interfaces/rating.interface';
import { IClinic } from '@app/interfaces/clinic.interface';

export const getRatings = async (site: ISite, clinic: IClinic, limit: number, offset: number) => {
  try {
    const result = await doGet(`/api/rating`, {
      params: {
        clinic: clinic?.id,
        site: site?.id,
        limit,
        offset,
      },
    });

    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const addRating = async (rating: IRating) => {
  try {
    const result = await doPost('/api/rating', { rating });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateRating = async (rating: IRating) => {
  try {
    const result = await doPut(`/api/rating/${rating.id}`, { rating });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteRating = async (rating: IRating) => {
  try {
    await doDelete(`/api/rating/${rating.id}`, {});
  } catch (e) {
    console.log('e', e);
  }
};
