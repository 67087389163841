import { doDelete, doGet, doPost, doPut } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { NEW_SITE } from '@app/constants/site';
import { ISite } from '@app/interfaces/site.interface';

export const getSites = async () => {
  try {
    const result = await doGet('/api/site', {});

    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const addSite = async (newSite: typeof NEW_SITE) => {
  try {
    const result = await doPost('/api/site', { site: newSite });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateSite = async (site: ISite) => {
  try {
    const result = await doPut(`/api/site/${site.id}`, { site });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteSite = async (site: ISite) => {
  try {
    await doDelete(`/api/site/${site.id}`, {});
  } catch (e) {
    console.log('e', e);
  }
};
