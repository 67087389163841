import { doGet } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { IVisit } from '@app/interfaces/visit.interface';
import dayjs from 'dayjs';
import { IIdentStaff } from '@app/interfaces/ident.reception.interface';

export const fetchVisitsInfoByClient = async (clientId: string) => {
  try {
    const result = await doGet(`/api/visit/client/${clientId}/visits`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getSmsInfo = (visit: IVisit) => {
  return visit.sms?.map((sms) => `- ${dayjs(sms.sentAt).format('DD MMMM YYYY HH:mm')}`).join('\n');
};

export const getFullName = (visit: IVisit) => {
  const { firstname, lastname } =
    visit.firstname && visit.lastname
      ? { firstname: visit.firstname, lastname: visit.lastname }
      : {
          firstname: visit.client.firstname,
          lastname: visit.client.lastname,
        };

  return `${lastname} ${firstname}`;
};

export const getIdentStaffName = (doctor: IIdentStaff) => {
  return ['surname', 'name', 'patronymic']
    .map((field) => doctor[field])
    .filter(Boolean)
    .join(' ');
};

export const getPhone = (visit: IVisit) =>
  `тел.: ${visit.phone ? visit.phone : visit.client.phone}`;
