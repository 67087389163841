import React from 'react';
import { Box, Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import type { IDoctor } from '@app/interfaces/doctor.interface';
import DoctorEditForm from '@app/pages/clinicPage/components/doctorEditForm/DoctorEditForm';
import globalAppStore from '@app/stores/globalAppStore';

type Props = {
  doctor: IDoctor;
  onEdit: (doctor: Partial<IDoctor>) => void;
  onDelete: (doctor: IDoctor) => void;
};

function DoctorCard({ doctor, onEdit, onDelete }: Props) {
  const [isEdit, setIsEdit] = React.useState(false);
  const handleDelete = async () => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление Доктора',
      text: doctor.fio,
      subtext: doctor.profession,
      confirmButtonTitle: 'Удалить',
      onConfirm: () => onDelete(doctor),
    });
  };

  if (isEdit) {
    return <DoctorEditForm doctor={doctor} onSave={onEdit} onCancel={() => setIsEdit(false)} />;
  }

  return (
    <Card sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <CardMedia component='img' sx={{ width: 160 }} image={doctor.imageUrl} alt={doctor.fio} />
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component='div' variant='h5' color='text.error'>
            {doctor.fio}
          </Typography>
          <Typography variant='subtitle1' color='text.secondary' component='div'>
            {doctor.profession}
          </Typography>
          <Typography variant='subtitle1' component='div'>
            Стаж: {doctor.experience}
          </Typography>
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            pl: 1,
            pb: 1,
          }}
        >
          <IconButton aria-label='edit-doctor' onClick={() => setIsEdit(true)}>
            <EditIcon sx={{ height: 24, width: 24 }} color='success' />
          </IconButton>
          <IconButton aria-label='delete-doctor' onClick={handleDelete}>
            <DeleteIcon sx={{ height: 24, width: 24 }} color='error' />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
}

export default DoctorCard;
