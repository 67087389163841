import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  TextField,
} from '@mui/material';

import type { IDoctor } from '@app/interfaces/doctor.interface';
import React from 'react';
import { DoctorFormConfig, EMPTY_DOCTOR } from '@app/constants/doctor';

type Props = {
  doctor?: IDoctor;
  onSave: (doctor: Partial<IDoctor>) => void;
  onCancel: () => void;
};

function DoctorEditForm(props: Props) {
  const [doctor, setDoctor] = React.useState<Partial<IDoctor>>(
    props.doctor ?? EMPTY_DOCTOR
  );

  const [isLoading, setIsLoading] = React.useState(false);

  const changeDoctor =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setDoctor({
        ...doctor,
        [field]: e.target.value,
      });
    };

  const saveDoctor = async () => {
    setIsLoading(true);
    await props.onSave(doctor);
    props.onCancel();
  };

  const onCancel = () => props.onCancel();

  const isValid = () =>
    DoctorFormConfig.filter((cnf) => cnf.required).every((cnf) =>
      Boolean(doctor[cnf.field])
    );

  return (
    <Card sx={{ display: 'flex' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <CardMedia
          component='img'
          sx={{ width: 160 }}
          image={
            doctor?.imageUrl ? doctor?.imageUrl : '/assets/images/no-image.jpg'
          }
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '28px',
          }}
        >
          <Button disabled={isLoading} onClick={onCancel}>
            Отмена
          </Button>

          <Divider sx={{ height: 20 }} />

          <Button
            variant='contained'
            onClick={saveDoctor}
            disabled={!isValid() || isLoading}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          {DoctorFormConfig.map(({ label, field, ...props }, idx) => {
            return (
              <TextField
                key={field}
                autoFocus={idx === 0}
                margin='dense'
                id={field}
                label={label}
                fullWidth
                value={doctor[field]}
                onChange={changeDoctor(field)}
                {...props}
              />
            );
          })}
        </CardContent>
      </Box>
    </Card>
  );
}

export default DoctorEditForm;
