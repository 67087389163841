import React from 'react';
import { Avatar, Box, Card, CardHeader, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import type { ClinicPropType } from '@app/pages/clinicsPage/components/clinicProp.type';
import { getClinicAcronym, getClinicFullAddress } from '@app/utils/clinic';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';

function ClinicListItem({ clinic }: ClinicPropType) {
  return (
    <Box component='li'>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label='clinic'>
              {getClinicAcronym(clinic)}
            </Avatar>
          }
          action={
            <Link to={`/admin/clinics/${clinic.id}`}>
              <IconButton aria-label='view clinic'>
                <ArrowForwardIosIcon />
              </IconButton>
            </Link>
          }
          title={clinic.name}
          subheader={getClinicFullAddress(clinic)}
          titleTypographyProps={{ variant: 'h6' }}
        />
      </Card>
    </Box>
  );
}

export default ClinicListItem;
