import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DatePicker } from '@mui/x-date-pickers';
import xor from 'lodash.xor';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PageTitle from '@app/components/pageTitle/PageTitle';
import AddVisitButton from '@app/pages/visitsPage/components/AddVisitButton';
import FetchVisits from '@app/pages/visitsPage/components/FetchVisits';
import ShowVisitsInfoByClient from '@app/pages/visitsPage/components/ShowVisitsInfoByClient';
import UploadExcelFileButton from '@app/pages/visitsPage/components/UploadExcelFileButton';
import SmsIcon from '@mui/icons-material/Sms';
import EditIcon from '@mui/icons-material/Edit';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MoreIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';

import globalAppStore from '@app/stores/globalAppStore';
import VisitsPageStore from '@app/stores/pages/visitsPageStore';

import { doDelete } from '@app/api';
import { sendCountedSms, sendSelectedVisitsSms } from '@app/api/visit';
import { fetchClinics, fetchClinicVisit, getClinicFullAddress } from '@app/utils/clinic';
import { getFullName, getPhone } from '@app/utils/visit';
import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IOperator } from '@app/interfaces/operator.interface';
import type { IVisit } from '@app/interfaces/visit.interface';
import { API_RESPONSE_STATUS_CODE, FEEDBACK_URL } from '@app/constants/common';

dayjs.locale('ru');

const store = new VisitsPageStore();

type AnchorsListType = HTMLElement | null;

const VisitsPage = observer(() => {
  const [isLoadingId, setIsLoadingId] = React.useState<number | null>(null);
  const [isDeletingId, setIsDeletingId] = React.useState<number | null>(null);
  const [operator, setOperator] = React.useState<IOperator>();
  const [clinic, setClinic] = React.useState<IClinic>();
  const [clinics, setClinics] = React.useState<IClinic[] | []>([]);
  const [date, setDate] = React.useState<Dayjs>(() => dayjs());
  const [anchorsList, setAnchorsList] = React.useState<AnchorsListType[] | []>([]);
  const [selectedVisits, setSelectedVisits] = React.useState<string[]>([]);
  const [isSelectedVisitsSendingSms, setIsSelectedVisitsSendingSms] = React.useState(false);

  React.useEffect(() => {
    fetchClinics().then((clinics) => setClinics(clinics));
  }, []);

  React.useEffect(() => {
    if (clinic) {
      fetchClinicVisit(clinic.id, date).then((visits) => store.setVisits(visits));
    } else {
      store.setVisits([]);
    }
  }, [clinic, date]);

  React.useEffect(() => {
    if (!globalAppStore.isAdmin && globalAppStore.loggedUser?.clinic.id) {
      fetchClinicVisit(globalAppStore.loggedUser?.clinic.id, date).then((visits) =>
        store.setVisits(visits)
      );
    }
  }, [date]);

  const activeOperators = React.useMemo(
    () => clinic?.operators?.filter((operator) => !operator.isDeleted) || [],
    [clinic]
  );

  const handleMenu = (idx: number) => (event: React.MouseEvent<HTMLElement>) => {
    const updatedAnchorsList = [...anchorsList];
    updatedAnchorsList[idx] = event.currentTarget;
    setAnchorsList(updatedAnchorsList);
  };

  const handleClose = (idx: number) => () => {
    if (idx === -1) {
      closeAll();
    } else {
      const updatedAnchorsList = [...anchorsList];
      updatedAnchorsList[idx] = null;
      setAnchorsList(updatedAnchorsList);
    }
  };

  const closeAll = () => setAnchorsList([]);

  const selectClinic = (e: SelectChangeEvent) => {
    const clinic = clinics.find((c) => c.id === +e.target.value);
    setOperator(undefined);
    setClinic(clinic);
  };

  const selectOperator = (e: SelectChangeEvent) => {
    if (!clinic) {
      return;
    }
    const operator = clinic.operators.find((op) => {
      return op.id === e.target.value;
    });

    setOperator(operator);
  };

  const editVisit = (visit: IVisit) => {
    closeAll();
    store.setVisit(visit);
    store.openEditForm();
  };

  const deleteVisit = async (visit: IVisit) => {
    globalAppStore.updateGlobalConfirmPopupState({
      isVisible: true,
      title: 'Удаление визита',
      text: 'Вы уверены, что хотите удалить Визит?',
      subtext: `ФИО: ${getFullName(visit)}, ${getPhone(visit)}`,
      confirmButtonTitle: 'Удалить',
      onConfirm: async () => {
        setIsDeletingId(visit.id);
        const result = await doDelete(`/api/visit/${visit.slug}`, {});

        if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
          store.deleteVisit(visit);
          closeAll();
        }
        setIsDeletingId(null);
      },
    });
  };

  const handleSendSms = async (visit: IVisit) => {
    setIsLoadingId(visit.id);
    const updatedVisit = await sendCountedSms(visit);

    if (updatedVisit) {
      store.updateVisit(updatedVisit);
      setClinic(updatedVisit.clinic);
    }

    setIsLoadingId(null);
    closeAll();
  };

  const onSuccessFetchReceptions = () => {
    if (clinic) {
      fetchClinicVisit(clinic.id, date).then((visits) => store.setVisits(visits));
    }
  };

  const isSelectedAllVisits = store.visits.length === selectedVisits.length;

  const selectAllVisitsButtonClick = () => {
    if (isSelectedAllVisits) {
      setSelectedVisits([]);
    } else {
      const visitSlugs = store.visits.map((visit) => visit.slug);
      setSelectedVisits(visitSlugs);
    }
  };

  const selectVisitButtonClick = (slug: string) => {
    const updatedSelectedVisitIds = xor(selectedVisits, [slug]);
    setSelectedVisits(updatedSelectedVisitIds);
  };

  const handleSelectedVisitsSendSms = async () => {
    setIsSelectedVisitsSendingSms(true);

    const updatedClinic = await sendSelectedVisitsSms(clinic!.id!, selectedVisits);
    setClinic(updatedClinic);

    setSelectedVisits([]);

    const updatedVisits = await fetchClinicVisit(updatedClinic!.id, date);

    store.setVisits(updatedVisits);

    setIsSelectedVisitsSendingSms(false);
  };

  if (!clinics.length) {
    return <span>loading ...</span>;
  }

  return (
    <div className='content'>
      <PageTitle
        title='Визиты'
        action={
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              alignItems: 'center',
              gap: '24px',
            }}
          >
            {globalAppStore.isAdmin && clinic?.isIdent && operator ? (
              <FetchVisits
                date={date}
                operator={operator}
                clinicId={clinic.id}
                visits={store.visits}
                onSuccess={onSuccessFetchReceptions}
              />
            ) : null}

            <UploadExcelFileButton store={store} clinic={clinic} operator={operator} date={date} />

            <AddVisitButton store={store} clinic={clinic} operator={operator} date={date} />
          </Box>
        }
      />

      <Box
        sx={{
          flexGrow: 1,
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '230px 1fr 1fr',
          justifyContent: 'start',
          gap: '24px',
        }}
      >
        <DatePicker value={date} onChange={(val) => setDate(val as Dayjs)} format='DD MMMM YYYY' />

        {globalAppStore.isAdmin ? (
          <>
            <FormControl fullWidth sx={{ width: '100%' }}>
              <InputLabel id='clinic-select'>Клиника</InputLabel>
              <Select
                labelId='clinic-select'
                id='clinic'
                label='Клиника'
                value={(clinic?.id || '').toString()}
                onChange={selectClinic}
              >
                {clinics.map((clinic) => (
                  <MenuItem key={clinic.id} value={clinic.id} divider dense>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>{clinic.name}</Typography>
                      <Typography variant='caption' color='gray'>
                        {clinic.city}, {clinic.address}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ width: '100%' }}>
              <InputLabel id='operator-select'>Оператор</InputLabel>
              <Select
                labelId='operator-select'
                id='operator'
                label='Оператор'
                value={(operator?.id || '').toString()}
                onChange={selectOperator}
                disabled={!activeOperators.length}
              >
                {activeOperators.map((operator) => (
                  <MenuItem key={operator.id} value={operator.id}>
                    {operator.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <Box>
            <Typography variant='h5'>{globalAppStore.loggedUser?.clinic?.name || ''}</Typography>
            <Typography variant='body1' color={'text.secondary'}>
              {getClinicFullAddress(globalAppStore.loggedUser!.clinic)}
            </Typography>
          </Box>
        )}
      </Box>

      {clinic ? (
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: 'whitesmoke',
            padding: '4px 75px 4px 16px',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: 1,
              alignItems: 'baseline',
            }}
          >
            <Typography variant='subtitle2'>Отправлено СМС:</Typography>
            <Typography
              variant='h4'
              color={clinic!.smsSentCount < clinic!.smsContractCount ? 'green' : 'red'}
            >
              {clinic?.smsSentCount}
            </Typography>
            <Typography variant='h5'>/ {clinic?.smsContractCount}</Typography>
          </Box>

          {store.visits.length ? (
            <Box>
              <Button
                variant='contained'
                disabled={!selectedVisits.length}
                onClick={handleSelectedVisitsSendSms}
                sx={{ minWidth: '155px' }}
              >
                {isSelectedVisitsSendingSms ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Отправить СМС'
                )}
              </Button>
              <FormControlLabel
                value='end'
                control={
                  <Checkbox onClick={selectAllVisitsButtonClick} checked={isSelectedAllVisits} />
                }
                label='Выбрать всех'
                labelPlacement='start'
              />
            </Box>
          ) : null}
        </Box>
      ) : null}

      <TableContainer component={Paper} sx={{ margin: '24px 0' }}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell component='th' width={40} align='center'>
                №
              </TableCell>
              <TableCell component='th'>Пациент</TableCell>
              <TableCell component='th'>Доктор</TableCell>
              <TableCell component='th'>Комментарий</TableCell>
              <TableCell component='th' width={300}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {store.visits.map((visit, idx: number) => {
              const isSelected = selectedVisits.includes(visit.slug);

              return (
                <TableRow key={visit.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <Typography
                      variant='h5'
                      sx={{ width: '35px', marginRight: '8px', textAlign: 'right' }}
                    >
                      {idx + 1}.
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant='h6' fontWeight={400} noWrap>
                      {getFullName(visit)}
                    </Typography>
                    <Typography variant='subtitle2' fontWeight={300}>
                      {getPhone(visit)}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant='h6' fontWeight={400} noWrap>
                      {visit.doctor?.fio || ''}
                    </Typography>
                    <Typography variant='subtitle2' fontWeight={300}>
                      {visit.doctor?.profession || ''}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant='subtitle2' fontWeight={300}>
                      {visit.comment || ''}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <ShowVisitsInfoByClient visit={visit} />

                      {isLoadingId === visit.id ? (
                        <CircularProgress size={32} />
                      ) : (
                        <IconButton
                          aria-label='sms'
                          size='large'
                          color={visit.isSmsSent ? 'default' : 'primary'}
                          disabled={visit.isSmsSent}
                          onClick={() => handleSendSms(visit)}
                        >
                          <SmsIcon />
                        </IconButton>
                      )}

                      <Checkbox
                        checked={isSelected}
                        onClick={() => selectVisitButtonClick(visit.slug)}
                      />

                      <div>
                        <IconButton
                          size='large'
                          aria-label='account of current user'
                          aria-controls='menu-appbar'
                          aria-haspopup='true'
                          onClick={handleMenu(idx)}
                          color='inherit'
                        >
                          <MoreIcon />
                        </IconButton>
                        <Menu
                          id='menu-appbar'
                          anchorEl={anchorsList?.[idx] || null}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorsList?.[idx])}
                          onClose={handleClose(-1)}
                        >
                          <MenuItem onClick={() => editVisit(visit)}>
                            <ListItemIcon>
                              <EditIcon fontSize='small' color='primary' />
                            </ListItemIcon>
                            <ListItemText>Редактировать</ListItemText>
                          </MenuItem>

                          <Divider />

                          <MenuItem
                            onClick={() => handleSendSms(visit)}
                            disabled={isLoadingId === visit.id}
                          >
                            <ListItemIcon>
                              {isLoadingId === visit.id ? (
                                <CircularProgress size={16} />
                              ) : (
                                <SmsIcon fontSize='small' color='primary' />
                              )}
                            </ListItemIcon>
                            <ListItemText>Отправить СМС</ListItemText>
                          </MenuItem>

                          <CopyToClipboard text={`${FEEDBACK_URL}/${visit.slug}`} onCopy={closeAll}>
                            <MenuItem>
                              <ListItemIcon>
                                <InsertLinkIcon fontSize='small' color='primary' />
                              </ListItemIcon>
                              <ListItemText>Скопировать ссылку</ListItemText>
                            </MenuItem>
                          </CopyToClipboard>

                          <Divider />

                          <MenuItem
                            onClick={() => deleteVisit(visit)}
                            disabled={isDeletingId === visit.id}
                          >
                            <ListItemIcon>
                              {isDeletingId === visit.id ? (
                                <CircularProgress size={16} color='error' />
                              ) : (
                                <DeleteIcon fontSize='small' color='error' />
                              )}
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ color: 'error' }}>
                              Удалить
                            </ListItemText>
                          </MenuItem>
                        </Menu>
                      </div>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default VisitsPage;
