import React from 'react';
import { observer } from 'mobx-react-lite';
import Excel, { Buffer } from 'exceljs';
import { Dayjs } from 'dayjs';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useToggle from '@app/hooks/useToggle';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import VisitsPageStore from '@app/stores/pages/visitsPageStore';

import { saveVisits } from '@app/api/clinic';
import { fetchClinicVisit } from '@app/utils/clinic';
import type { IOperator } from '@app/interfaces/operator.interface';
import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IClient } from '@app/interfaces/client.interface';

type Props = {
  store: VisitsPageStore;
  operator?: IOperator;
  clinic?: IClinic;
  date: Dayjs;
};

const UploadExcelFileButton = observer(({ store, operator, clinic, date }: Props) => {
  const [clients, setClients] = React.useState<IClient[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [open, doOpen, doClose] = useToggle();

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];

    if (file) {
      const wb = new Excel.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;

        if (buffer) {
          wb.xlsx.load(buffer as Buffer).then((workbook) => {
            const data: IClient[] = [];

            workbook.eachSheet((sheet) => {
              sheet.eachRow((row) => {
                let [fio, phone] = Object.values(row.values);

                if (fio && phone) {
                  const [lastname, firstname] = fio.toString().split(' ');

                  phone = phone.toString().replace(/\D/g, '');

                  data.push({
                    id: '',
                    lastname,
                    firstname,
                    phone,
                  });
                }
              });
            });

            setClients(data);
            doOpen();
          });
        }
      };

      e.target.value = '';
    }
  };

  const handleSave = async () => {
    if (clinic && operator) {
      setLoading(true);

      await saveVisits(
        clinic.id,
        operator,
        clients.map((client) => ({
          firstname: client.firstname,
          lastname: client.lastname,
          phone: client.phone,
          visitedAt: date.format('DD.MM.YYYY'),
        }))
      );

      fetchClinicVisit(clinic.id, date).then((visits) => store.setVisits(visits));

      setLoading(false);

      doClose();
    }
  };

  if (!operator) {
    return null;
  }

  return (
    <>
      <Button component='label' startIcon={<CloudUploadIcon />} disabled={loading}>
        xlsx
        <VisuallyHiddenInput type='file' onInput={handleFile} disabled={loading} />
      </Button>

      <Dialog open={open} onClose={doClose} fullWidth maxWidth='sm'>
        <DialogTitle>Пациенты</DialogTitle>
        <DialogContent>
          <Table sx={{ minWidth: 450 }} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Фамилия</TableCell>
                <TableCell>Имя</TableCell>
                <TableCell>Телефон</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client, idx) => (
                <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell component='th' scope='row'>
                    {client.lastname}
                  </TableCell>
                  <TableCell>{client.firstname}</TableCell>
                  <TableCell>{client.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={doClose}>Отмена</Button>
          <Button variant='contained' onClick={handleSave}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default UploadExcelFileButton;
