import dayjs, { Dayjs } from 'dayjs';
import { doGet } from '@app/api';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';

export const fetchIdentReceptions = async (visitedAt: Dayjs) => {
  try {
    const result = await doGet(`/api/ident/receptions`, {
      params: { visitedAt: dayjs(visitedAt).format('DD-MM-YYYY') },
    });
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchIdentTreatDoctors = async () => {
  try {
    const result = await doGet(`/api/ident/doctors`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchIdentStaffs = async () => {
  try {
    const result = await doGet(`/api/ident/staffs`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const fetchIdentPersons = async () => {
  try {
    const result = await doGet(`/api/ident/persons`, {});
    if (result.status === API_RESPONSE_STATUS_CODE.SUCCESS) {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};
