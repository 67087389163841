import React from 'react';
import { observer } from 'mobx-react-lite';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import SiteSelect from '@app/pages/admin/ReviewsPage/components/SiteSelect';

import RatingPageStore from '@app/pages/admin/RatingPage/stores/ratingPageStore';

import type { IRating } from '@app/interfaces/rating.interface';
import type { ISite } from '@app/interfaces/site.interface';

type Props = {
  store: RatingPageStore;
};

const AddRatingButton = observer(({ store }: Props) => {
  const handleRatingChange = function <T>(field: keyof IRating) {
    return (value: T) => store.setItemField(field, value);
  };

  const handleSave = async () => {
    await store.saveRating();
  };

  return (
    <>
      <Button variant='outlined' onClick={store.openEditor}>
        Добавить рейтинг
      </Button>

      <Dialog open={store.isEditorOpen} onClose={store.closeEditor} fullWidth={true} maxWidth='sm'>
        <DialogTitle>Рейтинг</DialogTitle>

        <DialogContent>
          <Grid container spacing={1} direction='column'>
            <Grid item>
              <SiteSelect
                site={store.item.site}
                sites={store.sites}
                setSite={handleRatingChange<ISite>('site')}
              />
            </Grid>

            <Grid item container spacing={1} sx={{ mt: 0 }} columnSpacing={2} flex={1}>
              <Grid item flex={1}>
                <DatePicker
                  label='Дата рейтинга'
                  value={dayjs(store.item.ratingDate)}
                  onChange={(e) => handleRatingChange<Dayjs>('ratingDate')(dayjs(e))}
                  format='DD MMMM YYYY'
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>

              <Grid item flex={1}>
                <TextField
                  label='Рейтинг'
                  type='number'
                  fullWidth
                  value={store.item.rating}
                  onChange={(event) => {
                    handleRatingChange<number>('rating')(parseFloat(event.target.value));
                  }}
                />
              </Grid>
            </Grid>

            <Grid item>
              <TextField
                margin='dense'
                id='comment'
                label='Комментарий'
                multiline
                rows={2}
                fullWidth
                value={store.item.comment}
                onChange={(event) => {
                  handleRatingChange<string>('comment')(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSave} disabled={!store.isValid}>
            {store.item?.id ? 'Сохранить' : 'Добавить'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default AddRatingButton;
