import { ISite } from '@app/interfaces/site.interface';

export enum NewSiteField {
  NAME = 'name',
  URL = 'url',
}

export const NEW_SITE: Pick<ISite, NewSiteField> & Partial<Pick<ISite, 'id' | 'imageUrl'>> = {
  [NewSiteField.NAME]: '',
  [NewSiteField.URL]: '',
};

export const NewSiteFormConfig = [
  { label: 'Название', field: NewSiteField.NAME, required: true },
  { label: 'Ссылка на платформу', field: NewSiteField.URL, required: true },
];
