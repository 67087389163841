import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import PageTitle from '@app/components/pageTitle/PageTitle';

import NavigationBox from '@app/pages/admin/components/NavigationBox';

const AnalyticsPage = observer(() => {
  return (
    <div className='content'>
      <PageTitle title='Аналитика' action={<NavigationBox />} />

      <Box>ANALYTICS</Box>
    </div>
  );
});

export default AnalyticsPage;
