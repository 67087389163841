import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Status } from '../interfaces/status.interface';

interface IProps {
  status: string;
  setStatus: (status: string) => void;
}

function StatusSelect({ status, setStatus }: IProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>Статус</InputLabel>
      <Select
        sx={{ width: '450px' }}
        labelId='status-select'
        id='status'
        label='Статус'
        value={status}
        onChange={(e) => {
          setStatus(e.target.value);
        }}
      >
        {Object.entries(Status).map(([id, label]) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default StatusSelect;
