import React from 'react';
import { List, ListItem, ListItemText, Switch, Typography } from '@mui/material';
import { IClinic } from '@app/interfaces/clinic.interface';
import { updateClinic } from '@app/api/clinic';

enum INTEGRATION {
  MEDODS = 'MEDODS',
}

interface IProps {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}

function SettingBlockIntegrationTab({ clinic, setClinic }: IProps) {
  const onSwitchToggle = async (integration: INTEGRATION) => {
    const response = await updateClinic({
      ...clinic,
      integration: clinic.integration === integration ? '' : integration,
    });

    if (response.data) {
      setClinic(response.data);
    }
  };

  return (
    <List dense={true} sx={{ width: 450 }}>
      <ListItem sx={{ borderBottom: '1px solid lightgrey' }}>
        <Switch
          edge='start'
          onChange={() => onSwitchToggle(INTEGRATION.MEDODS)}
          checked={clinic.integration === INTEGRATION.MEDODS}
          sx={{ marginRight: 2 }}
          disabled={false}
        />

        <ListItemText primary={<Typography variant='h6'>МЕДОДС</Typography>} />
      </ListItem>
    </List>
  );
}

export default SettingBlockIntegrationTab;
