import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Rating,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import SiteSelect from '@app/pages/admin/ReviewsPage/components/SiteSelect';
import DoctorSelect from '@app/pages/admin/ReviewsPage/components/DoctorSelect';

import useForm from '@app/hooks/useForm';
import {
  NEW_REVIEW,
  NewReviewClientFormConfig,
  NewReviewField,
  NewReviewTextFormConfig,
  RequiredFields,
} from '@app/constants/review';
import type { ISite } from '@app/interfaces/site.interface';
import type { IDoctor } from '@app/interfaces/doctor.interface';
import type { IReview } from '@app/interfaces/review.interface';
import type { Dayjs } from 'dayjs';
import { addReview, updateReview } from '@app/api/review';
import dayjs from 'dayjs';

type Props = {
  review?: IReview;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSuccess: () => void;
};

function AddReviewButton({ review = NEW_REVIEW, isOpen, onOpen, onClose, onSuccess }: Props) {
  const { item, setItem, changeInput, changeItem } = useForm<typeof NEW_REVIEW>(review);

  React.useEffect(() => {
    setItem(review);
  }, [review, setItem]);

  const onAddReviewButtonClick = async () => {
    if (item?.id) {
      await updateReview(item);
    } else {
      await addReview(item);
    }

    onSuccess();
    onClose();
  };

  const isValidForm = () => {
    return RequiredFields.every((field) => Boolean(item[field]));
  };

  return (
    <>
      <Button variant='outlined' onClick={onOpen}>
        Добавить Отзыв
      </Button>

      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth='sm'>
        <DialogTitle>Отзыв</DialogTitle>

        <DialogContent>
          <Grid container spacing={1} sx={{ mt: 0 }} columnSpacing={4}>
            <Grid item>
              <DatePicker
                value={dayjs(item.reviewDate)}
                onChange={(e) => changeItem<Dayjs>(NewReviewField.DATE)(dayjs(e))}
                format='DD MMMM YYYY'
              />
            </Grid>

            <Grid item alignSelf='center'>
              <Rating
                name='simple-controlled'
                value={item.rating}
                onChange={(event, newValue) => {
                  changeItem<number>(NewReviewField.RATING)(newValue!);
                }}
              />
            </Grid>
          </Grid>

          <SiteSelect
            site={item.site}
            sites={[]}
            setSite={changeItem<ISite>(NewReviewField.SITE)}
          />

          <DoctorSelect
            clinic={item.clinic}
            doctor={item.doctor}
            setDoctor={changeItem<IDoctor>(NewReviewField.DOCTOR)}
          />

          <Grid container spacing={1} sx={{ mt: 0 }} columnSpacing={4}>
            <Grid item flex={1}>
              {NewReviewClientFormConfig.map(({ label, field, required }, idx) => {
                return (
                  <TextField
                    key={field}
                    autoFocus={idx === 0}
                    margin='dense'
                    id={field}
                    label={label}
                    fullWidth
                    required={required}
                    value={item[field]}
                    onChange={changeInput(field)}
                  />
                );
              })}
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.isRespectClient}
                      onChange={(e) => {
                        changeItem<boolean>(NewReviewField.IS_RESPECT_CLIENT)(e.target.checked);
                      }}
                    />
                  }
                  label='Респект-М'
                />
              </FormGroup>
            </Grid>
          </Grid>

          {NewReviewTextFormConfig.map(({ label, field, required, type }, idx) => {
            return (
              <TextField
                key={field}
                autoFocus={idx === 0}
                margin='dense'
                id={field}
                label={label}
                multiline={type === 'multiline'}
                rows={type === 'multiline' ? 2 : 1}
                fullWidth
                required={required}
                value={item[field]}
                onChange={changeInput(field)}
              />
            );
          })}
        </DialogContent>

        <DialogActions>
          <Button onClick={onAddReviewButtonClick} disabled={!isValidForm()}>
            {review?.id ? 'Сохранить' : 'Добавить'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddReviewButton;
