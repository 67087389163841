import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import useToggle from '@app/hooks/useToggle';
import { IClinic } from '@app/interfaces/clinic.interface';
import useClinic from '@app/hooks/useClinic';
import { updateClinic } from '@app/api/clinic';

type Props = {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
  config: {
    name: string;
    field: string;
  };
};

function EditUrlButton({ clinic, setClinic, config: { name, field } }: Props) {
  const [isOpen, doOpen, doClose] = useToggle(false);
  const [changeClinic] = useClinic(clinic, setClinic);

  const handleSave = async () => {
    await updateClinic(clinic);

    doClose();
  };

  return (
    <>
      <IconButton onClick={doOpen}>
        <LinkIcon color={'success'} />
      </IconButton>

      <Dialog open={isOpen} onClose={doClose} maxWidth='sm' fullWidth>
        <DialogTitle>{name}</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Ссылка'
            fullWidth
            variant='outlined'
            value={clinic[field]}
            onChange={changeClinic(field)}
          />
        </DialogContent>

        <DialogActions sx={{ padding: '0 24px 24px' }}>
          <Button onClick={doClose}>Отмена</Button>

          <Button onClick={handleSave} variant='contained'>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditUrlButton;
