import React from 'react';

type AnchorsListType = HTMLElement | null;

type ReturnType = [
  AnchorsListType[],
  (idx: number) => (event: React.MouseEvent<HTMLElement>) => void,
  (idx: number) => (event: React.MouseEvent<HTMLElement>) => void,
  () => void,
];

function useDropdownMenu(): ReturnType {
  const [anchorsList, setAnchorsList] = React.useState<AnchorsListType[] | []>(
    []
  );

  const handleOpen =
    (idx: number) => (event: React.MouseEvent<HTMLElement>) => {
      const updatedAnchorsList = [...anchorsList];
      updatedAnchorsList[idx] = event.currentTarget;
      setAnchorsList(updatedAnchorsList);
    };

  const handleClose = (idx: number) => () => {
    if (idx === -1) {
      closeAll();
    } else {
      const updatedAnchorsList = [...anchorsList];
      updatedAnchorsList[idx] = null;
      setAnchorsList(updatedAnchorsList);
    }
  };

  const closeAll = () => setAnchorsList([]);

  return [anchorsList, handleOpen, handleClose, closeAll];
}

export default useDropdownMenu;
