import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import globalAppStore from '@app/stores/globalAppStore';

function LoginPage() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = async () => {
    await globalAppStore.logIn(email, password);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          minWidth: 275,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px 0 16px',
        }}
      >
        <CardMedia
          sx={{ height: 100, width: '100%', backgroundSize: 'contain' }}
          image='/assets/images/logo.jpg'
          title='respect-m'
        />
        <CardContent>
          <TextField
            autoFocus={true}
            margin='dense'
            id='email'
            label='Email'
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <FormControl sx={{ mt: 1.5, width: '100%' }} variant='outlined'>
            <InputLabel htmlFor='password'>Пароль</InputLabel>
            <OutlinedInput
              id='password'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Пароль'
            />
          </FormControl>
        </CardContent>
        <CardActions sx={{ alignSelf: 'end', padding: '16px' }}>
          <Button
            variant='contained'
            color={'primary'}
            size='large'
            onClick={onSubmit}
            disabled={!email || !password}
          >
            войти
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default LoginPage;
