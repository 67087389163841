import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { fetchDoctorsByClinic } from '@app/api/doctor';
import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IDoctor } from '@app/interfaces/doctor.interface';

type Props = {
  clinic: IClinic | null;
  doctor: IDoctor | null;
  setDoctor: (doctor: IDoctor) => void;
};

function DoctorSelect({ clinic, doctor, setDoctor }: Props) {
  const [items, setItems] = React.useState<IDoctor[]>([]);

  React.useEffect(() => {
    if (clinic) {
      fetchDoctorsByClinic(clinic.id).then((data: IDoctor[]) => {
        setItems(data);
      });
    }
  }, [clinic]);

  const handleSelect = (e: SelectChangeEvent<string>) => {
    const _item = items.find((item) => item.id === +e.target.value);

    if (_item) {
      setDoctor(_item);
    }
  };

  if (!items.length) {
    return null;
  }

  return (
    <Box sx={{ marginTop: 1, display: 'flex', alignItems: 'center' }}>
      <FormControl fullWidth>
        <InputLabel id='clinic-select'>Доктор</InputLabel>
        <Select
          labelId='clinic-select'
          id='clinic'
          label='Доктор'
          value={doctor?.id?.toString() || ''}
          onChange={handleSelect}
        >
          {items.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id} divider dense>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{item.fio}</Typography>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default DoctorSelect;
