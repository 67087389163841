import React from 'react';
import styled from '@emotion/styled';
import { Button, Paper, TextField, TextFieldProps, Typography } from '@mui/material';

import type { IClinic } from '@app/interfaces/clinic.interface';
import { saveTemplate } from '@app/api/clinic';
import { MESSAGE_FIELD_NAME } from '@app/constants/clinic';

interface Props {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}

const DEFAULT_MESSAGE =
  '%FIRST_NAME%, расскажите, пожалуйста, что Вам не понравилось в нашей клинике.\n\nМы обязательно разберемся и предложим Вам решение проблемы.';

function FailureMessageEditor({ clinic, setClinic }: Props) {
  const [template, setTemplate] = React.useState(clinic.failureSmsTemplate || DEFAULT_MESSAGE);

  const templateConfig = React.useMemo(
    () => [
      {
        tmpl: '%FIRST_NAME%',
        label: 'Имя пациента',
        value: 'Иван',
      },
    ],
    []
  );

  const message = React.useMemo(() => {
    let tmplString = template;

    templateConfig.forEach(({ tmpl, value }) => {
      tmplString = tmplString.replace(tmpl, value);
    });

    return tmplString;
  }, [templateConfig, template]);

  const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplate(e.target.value);
  };

  const handleTemplateSave = async () => {
    saveTemplate(clinic.id, MESSAGE_FIELD_NAME.FAILURE, template).then((clinic) => {
      if (clinic) {
        setClinic(clinic);
      }
    });
  };

  return (
    <>
      <Typography variant={'h5'} sx={{ mb: 1 }}>
        Негативный отзыв
      </Typography>

      <Paper variant='outlined' sx={{ padding: 2 }}>
        {templateConfig.map(({ tmpl, label }) => (
          <Typography key={label}>{`${tmpl} - ${label}`}</Typography>
        ))}
      </Paper>

      <Divider />

      <GridWrapper>
        <Editor
          id='welcome-message-template'
          label='Шаблон'
          multiline
          rows={5}
          minRows={5}
          fullWidth
          value={template}
          onChange={handleTemplateChange}
        />

        <Editor
          id='welcome-message-example'
          label='Итог'
          multiline
          rows={5}
          minRows={5}
          fullWidth
          disabled
          value={message}
        />
      </GridWrapper>

      <Divider />

      <Button onClick={handleTemplateSave} disabled={clinic?.failureSmsTemplate === template}>
        Сохранить
      </Button>
    </>
  );
}

const Editor = styled(TextField)<TextFieldProps>({
  '.MuiInputBase-root': {
    background: '#f8f9ff',
  },
});

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Divider = styled.div`
  height: 30px;
`;

export default FailureMessageEditor;
