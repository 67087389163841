import React from 'react';
import { Box } from '@mui/material';
import ClinicListItem from '@app/pages/clinicsPage/components/ClinicListItem';

import type { clinicsListPropType } from '@app/pages/clinicsPage/components/clinicProp.type';

function ClinicsList({ clinics }: clinicsListPropType) {
  if (!clinics?.length) {
    return null;
  }

  return (
    <Box component='ul' sx={{ listStyle: 'none', padding: 0 }}>
      {clinics.map((clinic) => (
        <ClinicListItem key={clinic.id} clinic={clinic} />
      ))}
    </Box>
  );
}

export default ClinicsList;
