import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AppRoute } from '@app/constants/common';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import PlaceIcon from '@mui/icons-material/Place';

import globalAppStore from '@app/stores/globalAppStore';

import './header.css';

const linksConfig = [
  { link: AppRoute.CLINICS, title: 'Клиники' },
  { link: AppRoute.ADMIN_FEEDBACKS, title: 'Отзывы' },
  { link: AppRoute.VISITS, title: 'Визиты' },
];

const Header = observer(() => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position='sticky'>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <PlaceIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='/'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Респект-М
          </Typography>
          <PlaceIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant='h5'
            noWrap
            component='a'
            href=''
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Респект-М
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'grid' },
              gridAutoFlow: 'column',
              justifyContent: 'start',
              gap: '16px',
            }}
          >
            {globalAppStore.isAdmin
              ? linksConfig.map(({ link, title }) => (
                  <NavLink key={link} to={link} className='menu-item'>
                    {title}
                  </NavLink>
                ))
              : null}
          </Box>

          {/*<Box sx={{ mr: 3 }}>*/}
          {/*  <NavLink to={AppRoute.SITES} className='menu-item'>*/}
          {/*    Платформы*/}
          {/*  </NavLink>*/}
          {/*</Box>*/}

          {/*<Box sx={{ mr: 3 }}>*/}
          {/*  <NavLink to={AppRoute.REVIEWS} className='menu-item'>*/}
          {/*    Отзывы*/}
          {/*  </NavLink>*/}
          {/*</Box>*/}

          {/*<Box sx={{ mr: 3 }}>*/}
          {/*  <NavLink to={AppRoute.RATING} className='menu-item'>*/}
          {/*    Рейтинг*/}
          {/*  </NavLink>*/}
          {/*</Box>*/}

          <Box sx={{ mr: 4 }}>
            <NavLink to={AppRoute.MEDODS} className='menu-item'>
              Medods
            </NavLink>
          </Box>

          <Box sx={{ mr: 4 }}>
            <NavLink to={AppRoute.ANALYTICS} className='menu-item'>
              Аналитика
            </NavLink>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={globalAppStore?.loggedUser?.name || 'A'}
                  src='/static/images/avatar/2.jpg'
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={globalAppStore.logOut}>
                <Typography textAlign='center'>Выйти</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default Header;
