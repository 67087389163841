import React from 'react';
import ClinicOperatorsList from '@app/pages/clinicPage/components/clinicOperatorsList';
import type { IClinic } from '@app/interfaces/clinic.interface';

interface Props {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}

function SettingBlockOperatorTab({ clinic, setClinic }: Props) {
  return <ClinicOperatorsList clinic={clinic} setClinic={setClinic} />;
}

export default SettingBlockOperatorTab;
