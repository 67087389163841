export enum NewClinicField {
  NAME = 'name',
  CITY = 'city',
  ADDRESS = 'address',
  SLUG = 'slug',
  INFO = 'info',
  EMAIL = 'email',
  SMS_PRICE = 'smsPrice',
}

export const EMPTY_CLINIC = {
  [NewClinicField.NAME]: '',
  [NewClinicField.CITY]: '',
  [NewClinicField.ADDRESS]: '',
  [NewClinicField.SLUG]: '',
  [NewClinicField.INFO]: '',
  [NewClinicField.EMAIL]: '',
  [NewClinicField.SMS_PRICE]: '',
};

export const NewClinicFormConfig = [
  { label: 'Название', field: NewClinicField.NAME, required: true },
  { label: 'Город', field: NewClinicField.CITY, required: true },
  { label: 'Адрес', field: NewClinicField.ADDRESS, required: true },
  { label: 'Слаг', field: NewClinicField.SLUG, required: true },
  { label: 'Sms стоимость', field: NewClinicField.SMS_PRICE, required: true, type: 'number' },
  { label: 'Email', field: NewClinicField.EMAIL },
  { label: 'Информация', field: NewClinicField.INFO },
];

export const getRequiredField = () =>
  NewClinicFormConfig.filter(({ required }) => !!required).map(({ field }) => field);

export enum NewClinicOperatorField {
  NAME = 'name',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const EMPTY_CLINIC_OPERATOR = {
  id: '',
  [NewClinicOperatorField.NAME]: '',
  [NewClinicOperatorField.EMAIL]: '',
  [NewClinicOperatorField.PASSWORD]: '',
};

export const NewClinicOperatorInputConfig = [
  { label: 'Имя', field: NewClinicOperatorField.NAME },
  { label: 'Email', field: NewClinicOperatorField.EMAIL },
  {
    label: 'Пароль',
    field: NewClinicOperatorField.PASSWORD,
    type: 'password',
  },
];

export enum FEEDBACK_SITE_NAMES {
  PRODOCTOROV = 'PRODOCTOROV',
  TWOGIS = 'TWOGIS',
  YANDEXMAP = 'YANDEXMAP',
  GOOGLEMAPS = 'GOOGLEMAPS',
  NAPOPRAVKU = 'NAPOPRAVKU',
  DOCDOC = 'DOCDOC',
}

export const LINK_CONFIG: { [field: string]: { name: string; field: string } } = {
  [FEEDBACK_SITE_NAMES.PRODOCTOROV]: {
    name: 'ПроДокторов',
    field: 'proDoctorovUrl',
  },
  [FEEDBACK_SITE_NAMES.YANDEXMAP]: {
    name: 'Яндекс карты',
    field: 'yandexUrl',
  },
  [FEEDBACK_SITE_NAMES.TWOGIS]: {
    name: '2Gis',
    field: 'twoGisUrl',
  },
  [FEEDBACK_SITE_NAMES.GOOGLEMAPS]: {
    name: 'Google Maps',
    field: 'googleMapUrl',
  },
  [FEEDBACK_SITE_NAMES.NAPOPRAVKU]: {
    name: 'НаПоправку',
    field: 'naPopravkuUrl',
  },
  [FEEDBACK_SITE_NAMES.DOCDOC]: {
    name: 'СберЗдоровье',
    field: 'docdocUrl',
  },
};

export const MESSAGE_FIELD_NAME = {
  FAILURE: 'failureSmsTemplate',
  WELCOME: 'welcomeSmsTemplate',
};
