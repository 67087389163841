export enum NewDoctorField {
  FIO = 'fio',
  PROFESSION = 'profession',
  EXPERIENCE = 'experience',
  FEEDBACK_URL = 'feedbackUrl',
  IMAGE_URL = 'imageUrl',
}

export const EMPTY_DOCTOR = {
  [NewDoctorField.FIO]: '',
  [NewDoctorField.PROFESSION]: '',
  [NewDoctorField.EXPERIENCE]: '',
  [NewDoctorField.FEEDBACK_URL]: '',
  [NewDoctorField.IMAGE_URL]: '',
};

export const DoctorFormConfig = [
  { label: 'ФИО', field: NewDoctorField.FIO, required: true },
  { label: 'Специальность', field: NewDoctorField.PROFESSION },
  { label: 'Стаж', field: NewDoctorField.EXPERIENCE },
  { label: 'Отзыв ссылка', field: NewDoctorField.FEEDBACK_URL, required: true },
  { label: 'Фото ссылка', field: NewDoctorField.IMAGE_URL, required: true },
];
