import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { PageTitleProps } from '@app/components/pageTitle/pageTitle.props';

function PageTitle(pageTitleProps: PageTitleProps) {
  const { title, addButton, action } = pageTitleProps;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '24px',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h4'>{title}</Typography>

        <Divider sx={{ width: 16 }} />

        {addButton}
      </Box>
      {action}
    </Box>
  );
}

export default PageTitle;
